import { MouseEvent, useMemo, useState } from 'react';
import { IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import { EllipsisIcon } from 'components/icons';
import { ProjectsItemData } from 'types/Projects';
import colors from 'theme/colors';
import { useUserData } from 'hooks';
import { MemberActionDialogType } from 'types/common';
import { MembershipItemData } from 'types/Membership';
import ActionDialog from './ActionDialog';

interface Props {
  project: ProjectsItemData;
  membershipData: MembershipItemData;
  closeSettingsDialog?: () => void;
}

const ActionsMenu = (props: Props): JSX.Element | null => {
  const { project, membershipData, closeSettingsDialog } = props;
  const { userData } = useUserData();

  const isOwner = useMemo(
    () => userData?._id === project.owner?._id,
    [project.owner?._id, userData?._id]
  );
  const isMember = useMemo(
    () => userData?._id === membershipData.member?._id,
    [membershipData.member?._id, userData?._id]
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [actionDialogType, setActionDialogType] = useState<MemberActionDialogType | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (type: MemberActionDialogType) => {
    setActionDialogType(type);
  };

  const handleMenuClose = async () => {
    setActionDialogType(null);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `member-actions-${project._id}-popover` : undefined;

  if (!isOwner && !isMember) {
    return null;
  }

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <EllipsisIcon
          fontSize="small"
          sx={(theme) => ({
            color: theme.palette.mode === 'dark' ? colors.white : colors.black,
          })}
        />
      </IconButton>
      <Menu
        id={id}
        anchorEl={actionDialogType ? null : anchorEl}
        open={actionDialogType ? false : open}
        onClose={handleClose}
        disablePortal={false}
      >
        {isOwner && (
          <MenuItem onClick={() => handleMenuClick('transferOwnership')}>
            <ListItemText primary="Transfer ownership" />
          </MenuItem>
        )}
        <MenuItem onClick={() => handleMenuClick(isMember ? 'leaveProject' : 'removeUser')}>
          <ListItemText primary={isMember ? 'Leave' : 'Remove'} />
        </MenuItem>
      </Menu>
      {actionDialogType && (
        <ActionDialog
          type={actionDialogType}
          membershipData={membershipData}
          project={project}
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          closeSettingsDialog={closeSettingsDialog}
        />
      )}
    </>
  );
};

export default ActionsMenu;
