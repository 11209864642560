import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const EmojiIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <g stroke="currentColor">
        <g>
          <g>
            <g transform="translate(-348 -1468) translate(260 1287) translate(88 181) translate(2 2)">
              <circle cx="10" cy="10" r="10" strokeWidth="1.5" />
              <path strokeWidth="1.5" d="M6 12s1.5 2 4 2 4-2 4-2" />
              <path strokeWidth="2" d="M7 7L7.01 7M13 7L13.01 7" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default EmojiIcon;
