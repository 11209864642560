import { useCallback, useMemo } from 'react';
import { Button, Card, CardActions, CardHeader, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { mutate } from 'swr';
import { ProjectsItemData } from 'types/Projects';
import { useApiRequest } from 'hooks';
import integrationsApi from 'api/integrationsApi';
import { Loader } from 'components';
import { AtlassianIcon } from 'components/icons';
import projectsApi from 'api/projectsApi';
import { appLinks } from 'routes/routes';
import ExportParamsDialog from './ExportParamsDialog';

interface Props {
  project: ProjectsItemData;
}

const Atlassian = (props: Props): JSX.Element | null => {
  const { project } = props;
  const history = useHistory();

  const { requestFn, isLoading } = useApiRequest(integrationsApi.disconnectAtlassian);

  const handleConnect = useCallback(async () => {
    history.push(appLinks.connectAtlassian.as(project._id));
  }, [history, project._id]);

  const handleDisconnect = useCallback(async () => {
    await requestFn({
      args: { projectId: project._id },
    });

    await mutate(projectsApi.urls.getItem(project._id));
  }, [project._id, requestFn]);

  const subheader = useMemo(() => {
    if (project.atlassianConfig) {
      return (
        <Typography variant="body2" color="text.secondary">
          Connected to{' '}
          <Typography variant="inherit" component="span" color="primary">
            {project.atlassianConfig.jiraProject.name}
          </Typography>
        </Typography>
      );
    }

    return (
      <Typography variant="body2" color="text.secondary">
        Connect your Atlassian account and make it easy for your team to stay on top of comments.
      </Typography>
    );
  }, [project.atlassianConfig]);

  return (
    <Card sx={{ p: 1 }}>
      <CardHeader
        avatar={<AtlassianIcon sx={{ width: 40, height: 40 }} />}
        title="Atlassian"
        subheader={subheader}
        titleTypographyProps={{
          sx: { fontSize: '16px' },
        }}
      />
      <CardActions>
        {project.atlassianConfig ? (
          <>
            <Button
              size="large"
              variant="outlined"
              color="secondary"
              onClick={handleDisconnect}
              disabled={isLoading}
              endIcon={isLoading ? <Loader inButton /> : undefined}
            >
              Disconnect
            </Button>
            <ExportParamsDialog project={project} type="github" />
          </>
        ) : (
          <Button size="large" color="primary" onClick={handleConnect}>
            Connect
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default Atlassian;
