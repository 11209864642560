import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectsItemData } from 'types/Projects';
import { MentionSuggestionOption } from 'types/common';
import projectsActions from 'store/actions/projectsActions';
import { StoreRootState } from 'store';

interface UseMentionSuggestion {
  mentionSuggestionOptions: MentionSuggestionOption[];
  setMentionSuggestionOptions: (project: ProjectsItemData) => void;
}

const useMentionSuggestions = (): UseMentionSuggestion => {
  const {
    auth: { userData },
    projects: { mentionSuggestionOptions },
  } = useSelector((state: StoreRootState) => state);
  const dispatch = useDispatch();

  const setMentionSuggestionOptions = useCallback(
    (project: ProjectsItemData) => {
      const options: MentionSuggestionOption[] = [
        ...project.members.map((m) => ({
          id: m._id,
          name: m.member?.username || m.recipient,
          color: m.color,
          avatar: m.member?.avatar?.mediaUrl,
        })),
        {
          id: project.owner?._id || '',
          name: project.owner?.username || '',
          avatar: project.owner?.avatar?.mediaUrl,
        },
      ].filter((m) => m.id !== userData?._id);

      dispatch(projectsActions.setMentionSuggestionOptions(options));
    },
    [dispatch, userData?._id]
  );

  return {
    mentionSuggestionOptions,
    setMentionSuggestionOptions,
  };
};

export default useMentionSuggestions;
