import { useCallback } from 'react';
import { Button } from '@mui/material';
import { useApiRequest } from 'hooks';
import authApi from 'api/authApi';
import { useHistory } from 'react-router-dom';
import { appLinks } from 'routes/routes';
import { AuthSendSignInCodeParams } from 'types/Auth';

interface Props {
  email: string;
  disabled: boolean;
}

const SendCodeButton = (props: Props): JSX.Element => {
  const { disabled, email } = props;
  const history = useHistory();

  const { requestFn, isLoading } = useApiRequest(authApi.sendSignInCode);

  const handleClick = useCallback(async () => {
    const data: AuthSendSignInCodeParams = {
      email,
    };

    await requestFn({
      args: data,
      successMessage: 'Code was sent to your email',
    });

    history.push(`${appLinks.signInConfirmCode.link}?email=${encodeURIComponent(email)}`);
  }, [email, requestFn, history]);

  return (
    <Button
      variant="outlined"
      color="secondary"
      disabled={disabled || isLoading}
      onClick={handleClick}
    >
      Send me a code
    </Button>
  );
};

export default SendCodeButton;
