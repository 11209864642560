/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useState } from 'react';
import useSnackbarNotification from './useSnackbarNotification';

export type FetcherFn<Data, Args> = (...args: Array<Args>) => Data | Promise<Data>;

export interface RequestFnParams<Args = any> {
  args?: Args;
  successMessage?: string;
  callback?: () => void;
}

export type ResponseInterface<Data, Error, Args> = {
  data?: Data;
  error?: Error;
  isLoading?: boolean;
  requestFn(params: RequestFnParams<Args>): Promise<Data>;
};

const useApiRequest = <Data = any, Error = any, Args = any>(
  fetcher: FetcherFn<Data, Args>
): ResponseInterface<Data, Error, Args> => {
  const { setSnackbarNotification } = useSnackbarNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [data, setData] = useState<Data | undefined>(undefined);

  const requestFn = useCallback(
    async (params: RequestFnParams) => {
      try {
        setIsLoading(true);
        setError(undefined);

        const response = await fetcher(params.args);

        if (params.successMessage) {
          setSnackbarNotification({
            severity: 'success',
            message: params.successMessage,
          });
        }

        setData(response);
        setIsLoading(false);

        if (params.callback) {
          params.callback();
        }

        return response;
      } catch (e: any) {
        setError(e);
        setIsLoading(false);

        setSnackbarNotification({
          severity: 'error',
          message: e?.response?.data?.message || e?.message,
        });

        throw e;
      }
    },
    [fetcher, setSnackbarNotification]
  );

  return {
    data,
    error,
    isLoading,
    requestFn,
  };
};

export default useApiRequest;
