import { useCallback } from 'react';
import { ProjectsItemData } from 'types/Projects';
import { Box, Button, Card, CardActions, CardHeader } from '@mui/material';
import { useApiRequest } from 'hooks';
import projectsApi from 'api/projectsApi';
import { MarkdownIcon, NotionIcon } from 'components/icons';
import { Loader } from 'components';

interface Props {
  project: ProjectsItemData;
}

const Markdown = (props: Props): JSX.Element | null => {
  const { project } = props;

  const { requestFn, isLoading } = useApiRequest(projectsApi.exportCommentsAsMarkdown);

  const handleClick = useCallback(async () => {
    const data = await requestFn({
      args: project._id,
    });

    const url = window.URL.createObjectURL(new Blob([data], { type: 'text/markdown' }));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `${project.title}.md`);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) {
      link.parentNode.removeChild(link);
    }
  }, [project._id, project.title, requestFn]);

  return (
    <Card sx={{ p: 1 }}>
      <CardHeader
        avatar={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MarkdownIcon sx={{ width: 40, height: 40, mr: 1 }} />
            <NotionIcon sx={{ width: 32, height: 32 }} />
          </Box>
        }
        title="Markdown"
        subheader="Download your project data as markdown."
        titleTypographyProps={{
          sx: { fontSize: '16px' },
        }}
      />
      <CardActions>
        <Button
          size="large"
          color="primary"
          onClick={handleClick}
          disabled={isLoading}
          endIcon={isLoading ? <Loader inButton /> : undefined}
        >
          Export
        </Button>
      </CardActions>
    </Card>
  );
};

export default Markdown;
