export default {
  lightPrimary: '#2982FF80',
  darkPrimary: '#1968D6',
  primary: '#3388FF',
  white: '#ffffff',
  black: '#010D1A',
  red: '#FA3318',
  yellow: '#ffc329',
  cian: '#31aba1',
  green: '#0C9E6A',
  darkGrey: '#313B41',
  darkGrey1: '#414b52',
  grey: '#8D949C',
  lightGrey: '#C9CED3',
  lightGrey2: '#EDEFF1',
  lightGrey3: '#F9FAFB',
  lightGrey4: '#E1E4EA',
};
