import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowLeftLongIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11.25 17.75L5.25 11.75L11.25 6.25" stroke="currentColor" strokeWidth="1.5" />
    <path d="M5.25 11.75L18.75 11.75" stroke="currentColor" strokeWidth="1.5" />
  </SvgIcon>
);

export default ArrowLeftLongIcon;
