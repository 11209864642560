import { CircularProgress, Box } from '@mui/material';

interface Props {
  color?: 'primary' | 'secondary' | 'inherit';
  inline?: boolean;
  height?: string;
  inButton?: boolean;
}

const Loader = (props: Props): JSX.Element => {
  const { color = 'primary', inline = false, height = 'auto', inButton = false } = props;

  if (inButton) {
    return <CircularProgress size={20} color="inherit" />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: inline ? 'auto' : height,
      }}
    >
      <CircularProgress color={color} />
    </Box>
  );
};

export default Loader;
