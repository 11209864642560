import { useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';

const SkeletonImage = (props: BoxProps<'img'>): JSX.Element | null => {
  const { sx, ...rest } = props;

  const [isLoading, setIsLoading] = useState(true);

  const handleLoaded = () => {
    setIsLoading(false);
  };

  return (
    <>
      <Box {...rest} sx={sx} component="img" loading="lazy" onLoad={handleLoaded} />
      {isLoading && (
        <Skeleton variant="rectangular" animation="wave" sx={{ minHeight: 250, ...sx }} />
      )}
    </>
  );
};

export default SkeletonImage;
