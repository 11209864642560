const ENDPOINTS = {
  AUTH: '/api/auth',
  USERS: '/api/users',
  PROJECTS: '/api/projects',
  PAYMENTS: '/api/payments',
  INTEGRATIONS: '/api/integrations',
  COMMENTS: '/api/comments',
  NOTIFICATIONS: '/api/notifications',
};

export default ENDPOINTS;
