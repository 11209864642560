import { AlertColor } from '@mui/material';
import { PaymentsPlanEnum } from './Payments';
import { NotificationsProjectSettingsParams } from './Notifications';

export interface ValidationData {
  statusCode: number;
  error: string;
  message: string;
}

export type ValidationResponse = Promise<ValidationData>;

export interface SubscriptionCardItem {
  id: string;
  plan: PaymentsPlanEnum;
  name: string;
  price: string;
  preReleasePrice: boolean;
  benefits: string[];
  buttonName: string;
}

export interface SnackbarNotificationData {
  message: string;
  severity: AlertColor;
}

export enum ProjectSettingsTabsEnum {
  DETAILS = 'Details',
  MEMBERS = 'Members',
  ALERTS = 'Alerts',
  INTEGRATIONS = 'Integrations',
}

export type MemberActionDialogType = 'removeUser' | 'leaveProject' | 'transferOwnership';

export interface MentionSuggestionOption {
  id: string;
  name: string;
  color?: string;
  avatar?: string;
}

export interface NotificationsAlertCheckboxOption {
  label: string;
  value: keyof NotificationsProjectSettingsParams;
}
