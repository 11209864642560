import { useMemo } from 'react';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import createMentionPlugin from '@draft-js-plugins/mention';
import { emojiPluginConfig, linkifyPluginConfig, mentionPluginConfig } from 'utils/draftJsConfig';
import createLinkifyPlugin from '@draft-js-plugins/linkify';

const useDraftPlugins = () => {
  return useMemo(() => {
    const emojiPlugin = createEmojiPlugin(emojiPluginConfig);
    const mentionPlugin = createMentionPlugin(mentionPluginConfig);
    const linkifyPlugin = createLinkifyPlugin(linkifyPluginConfig);
    const { MentionSuggestions } = mentionPlugin;
    const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
    const plugins = [emojiPlugin, mentionPlugin, linkifyPlugin];

    return {
      MentionSuggestions,
      EmojiSuggestions,
      EmojiSelect,
      plugins,
    };
  }, []);
};

export default useDraftPlugins;
