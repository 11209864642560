import { AuthActions, AuthActionsEnum, StoreAuthState } from '../types/authTypes';
import { GlobalActions, GlobalActionsEnum } from '../types/globalTypes';

const initialState: StoreAuthState = {
  userData: null,
  resendCodeEmail: undefined,
  resendCodeTimerData: undefined,
  guestUserData: undefined,
  isGuestUserModalOpen: false,
};

// eslint-disable-next-line default-param-last
const authReducer = (state = initialState, action: AuthActions | GlobalActions): StoreAuthState => {
  switch (action.type) {
    case AuthActionsEnum.SET_USER_DATA:
      return { ...state, userData: action.payload };
    case AuthActionsEnum.SET_RESEND_CODE_EMAIL:
      return { ...state, resendCodeEmail: action.payload };
    case AuthActionsEnum.SET_RESEND_CODE_TIMER_DATA:
      return { ...state, resendCodeTimerData: action.payload };
    case AuthActionsEnum.SET_GUEST_USER_DATA:
      return { ...state, guestUserData: action.payload };
    case AuthActionsEnum.SET_IS_GUEST_USER_MODAL_OPEN:
      return { ...state, isGuestUserModalOpen: action.payload };
    case GlobalActionsEnum.RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
