import { mutate } from 'swr';
import { Button, Grid, Typography } from '@mui/material';
import { useCallback, useState, KeyboardEvent } from 'react';
import { ProjectsInviteMemberParams, ProjectsItemData } from 'types/Projects';
import forms from 'constants/forms';
import { Loader, TextInput } from 'components';
import { useApiRequest } from 'hooks';
import projectsApi from 'api/projectsApi';

interface Props {
  project: ProjectsItemData;
}

const InviteForm = (props: Props): JSX.Element | null => {
  const { project } = props;

  const { requestFn, isLoading } = useApiRequest((value) =>
    projectsApi.inviteUserToProject(project._id, value)
  );

  const [inviteEmail, setInviteEmail] = useState('');
  const [isError, setIsError] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (isError) {
      setIsError(false);
    }

    if (!forms.inviteEmail.validationFn(inviteEmail)) {
      const submitModel: ProjectsInviteMemberParams = {
        recipient: inviteEmail,
      };
      await requestFn({
        args: submitModel,
        successMessage: 'Invite sent',
      });

      setInviteEmail('');
      await mutate(projectsApi.urls.getItem(project._id));
    } else {
      setIsError(true);
    }
  }, [inviteEmail, isError, project._id, requestFn]);

  const handleKeyDown = useCallback(
    async (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.key === 'Enter') {
        await handleSubmit();
      }
    },
    [handleSubmit]
  );

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Typography variant="subtitle1" color="text.primary">
          Invite users to your team
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container wrap="nowrap" alignItems="center">
          <Grid item sx={{ flexGrow: 1, mr: 2 }}>
            <TextInput
              inputParams={forms.inviteEmail}
              value={inviteEmail}
              onChange={setInviteEmail}
              isError={isError}
              InputProps={{
                onKeyDown: handleKeyDown,
              }}
            />
          </Grid>
          <Grid item xs="auto">
            <Button
              color="primary"
              onClick={handleSubmit}
              disabled={!inviteEmail}
              endIcon={isLoading ? <Loader inButton /> : undefined}
            >
              Invite
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InviteForm;
