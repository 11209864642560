import { Stack } from '@mui/material';
import { ProjectsItemData } from 'types/Projects';
import Slack from './Slack';
import Markdown from './Markdown';
import Github from './Github';
import Atlassian from './Atlassian';

interface Props {
  project: ProjectsItemData;
}

const IntegrationsTab = (props: Props): JSX.Element => {
  const { project } = props;

  return (
    <Stack rowGap={2} sx={{ width: '100%' }}>
      <Atlassian project={project} />
      <Github project={project} />
      <Slack project={project} />
      <Markdown project={project} />
    </Stack>
  );
};

export default IntegrationsTab;
