import { ProjectsItemData, ProjectsListData } from 'types/Projects';
import { MentionSuggestionOption } from '../../types/common';

export interface ProjectsActiveTab {
  id: number;
  host: string;
  pathname: string;
}

export interface StoreProjectAccessRights {
  accessRight: 'authorized' | 'unauthorized' | 'guest' | 'not_project_member';
  canAddComments: boolean;
}

export interface StoreProjectsState {
  projects?: ProjectsListData;
  activeProject?: ProjectsItemData;
  activeProjectAccessRights: StoreProjectAccessRights;
  mentionSuggestionOptions: MentionSuggestionOption[];
}

export enum ProjectsActionsEnum {
  SET_PROJECTS = 'SET_PROJECTS',
  SET_ACTIVE_PROJECT = 'SET_ACTIVE_PROJECT',
  SET_MENTION_SUGGESTION_OPTIONS = 'SET_MENTION_SUGGESTION_OPTIONS',
  SET_PROJECT_ACCESS_RIGHTS = 'SET_PROJECT_ACCESS_RIGHTS',
}

interface SetProjects {
  type: ProjectsActionsEnum.SET_PROJECTS;
  payload: ProjectsListData | undefined;
}

interface SetActiveProject {
  type: ProjectsActionsEnum.SET_ACTIVE_PROJECT;
  payload: ProjectsItemData | undefined;
}

interface SetProjectAccessRights {
  type: ProjectsActionsEnum.SET_PROJECT_ACCESS_RIGHTS;
  payload: StoreProjectAccessRights;
}

interface SetMentionSuggestionOptions {
  type: ProjectsActionsEnum.SET_MENTION_SUGGESTION_OPTIONS;
  payload: MentionSuggestionOption[];
}

export type ProjectsActions =
  | SetProjects
  | SetMentionSuggestionOptions
  | SetActiveProject
  | SetProjectAccessRights;
