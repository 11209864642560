import { useMemo } from 'react';
import { List, ListSubheader, Typography } from '@mui/material';
import { ProjectsItemData } from 'types/Projects';
import MembersListItem from './MembersListItem';

const Subheader = ({ title }: { title: string }): JSX.Element => {
  return (
    <ListSubheader disableGutters sx={{ bgcolor: 'unset' }}>
      <Typography variant="subtitle1" color="text.primary">
        {title}
      </Typography>
    </ListSubheader>
  );
};

interface Props {
  project: ProjectsItemData;
  closeSettingsDialog: () => void;
}

const MembersList = (props: Props): JSX.Element => {
  const { project, closeSettingsDialog } = props;

  const members = useMemo(() => {
    const pending = project.members.filter((m) => m.member === null);
    const accepted = project.members.filter((m) => m.member !== null);

    return {
      pending,
      accepted,
    };
  }, [project.members]);

  return (
    <List
      sx={{
        width: '100%',
        position: 'relative',
        '& ul': { padding: 0 },
      }}
    >
      {project.owner && (
        <li>
          <ul>
            <Subheader title="Owner" />
            <MembersListItem
              type="owner"
              project={project}
              name={project.owner.username}
              email={project.owner.email}
              avatar={project.owner.avatar?.mediaUrl}
            />
          </ul>
        </li>
      )}
      {members.pending.length > 0 && (
        <li>
          <ul>
            <Subheader title="Pending users" />
            {members.pending.map((item) => (
              <MembersListItem
                key={item._id}
                type="pending"
                project={project}
                membership={item}
                name={item.recipient}
                email={item.recipient}
                color={item.color}
              />
            ))}
          </ul>
        </li>
      )}
      {members.accepted.length > 0 && (
        <li>
          <ul>
            <Subheader title="Accepted users" />
            {members.accepted.map((item) => (
              <MembersListItem
                key={item._id}
                type="accepted"
                project={project}
                membership={item}
                name={item.member?.username || ''}
                email={item.member?.email}
                color={item.color}
                avatar={item.member?.avatar?.mediaUrl}
                closeSettingsDialog={closeSettingsDialog}
              />
            ))}
          </ul>
        </li>
      )}
    </List>
  );
};

export default MembersList;
