import { CommentsItemStatusEnum } from 'types/Comments';
import { CommentsActions, CommentsActionsEnum, StoreCommentsState } from '../types/commentsTypes';
import { GlobalActions, GlobalActionsEnum } from '../types/globalTypes';

const initialState: StoreCommentsState = {
  activeCommentId: undefined,
  editCommentId: undefined,
  editReplyIds: undefined,
  notAvailableCommentIds: [],
  refreshCommentsData: false,
  deviceTypeFilter: undefined,
  pathnameFilter: undefined,
  statusTypeFilter: CommentsItemStatusEnum.NEW,
};

const commentsReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: CommentsActions | GlobalActions
): StoreCommentsState => {
  switch (action.type) {
    case CommentsActionsEnum.SET_ACTIVE_COMMENT_ID:
      return { ...state, activeCommentId: action.payload };
    case CommentsActionsEnum.SET_EDIT_COMMENT_ID:
      return { ...state, editCommentId: action.payload };
    case CommentsActionsEnum.SET_EDIT_REPLY_IDS:
      return { ...state, editReplyIds: action.payload };
    case CommentsActionsEnum.SET_REFRESH_COMMENTS_DATA:
      return { ...state, refreshCommentsData: action.payload };
    case CommentsActionsEnum.SET_DEVICE_TYPE_FILTER:
      return { ...state, deviceTypeFilter: action.payload };
    case CommentsActionsEnum.SET_STATUS_TYPE_FILTER:
      return { ...state, statusTypeFilter: action.payload };
    case CommentsActionsEnum.SET_PATHNAME_FILTER:
      return { ...state, pathnameFilter: action.payload };
    case CommentsActionsEnum.ADD_NOT_AVAILABLE_COMMENT:
      return {
        ...state,
        notAvailableCommentIds: [...state.notAvailableCommentIds, action.payload],
      };
    case CommentsActionsEnum.REMOVE_NOT_AVAILABLE_COMMENT:
      return {
        ...state,
        notAvailableCommentIds: state.notAvailableCommentIds.filter((c) => c !== action.payload),
      };
    case GlobalActionsEnum.RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default commentsReducer;
