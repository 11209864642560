import useSWR from 'swr';
import { ChangeEvent, useCallback, useState } from 'react';
import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { ProjectsExportCommentsParams, ProjectsItemData } from 'types/Projects';
import projectsApi from 'api/projectsApi';
import colors from 'theme/colors';
import { CommentsItemStatusEnum } from 'types/Comments';
import { useApiRequest } from 'hooks';
import { Loader } from 'components';
import { TimesIcon } from 'components/icons';

interface Props {
  project: ProjectsItemData;
  type: 'atlassian' | 'github';
}

const COMMENTS_STATUSES = [
  { label: 'Opened', value: CommentsItemStatusEnum.NEW },
  { label: 'Resolved', value: CommentsItemStatusEnum.RESOLVED },
];

const ExportParamsDialog = (props: Props): JSX.Element | null => {
  const { project, type } = props;

  const { data, error } = useSWR(projectsApi.urls.getItemPages(project._id), () =>
    projectsApi.getItemPages(project._id)
  );
  const { requestFn, isLoading } = useApiRequest((value) =>
    type === 'github'
      ? projectsApi.exportCommentsToGithub(project._id, value)
      : projectsApi.exportCommentsToAtlassian(project._id, value)
  );

  const [isOpen, setIsOpen] = useState(false);
  const [isExportAllPages, setIsExportAllPages] = useState(true);
  const [selectedPages, setSelectedPages] = useState<string[]>([]);
  const [selectedCommentsStatuses, setSelectedCommentsStatuses] = useState<
    CommentsItemStatusEnum[]
  >([]);

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsExportAllPages(event.target.checked);
  };

  const handleSelectedStatusesChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedCommentsStatuses((prev) => [...prev, event.target.name as CommentsItemStatusEnum]);
    } else {
      setSelectedCommentsStatuses((prev) => prev.filter((p) => p !== event.target.name));
    }
  };

  const handleSelectedPagesChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedPages((prev) => [...prev, event.target.name]);
    } else {
      setSelectedPages((prev) => prev.filter((p) => p !== event.target.name));
    }
  };

  const handleSubmit = useCallback(async () => {
    if (data) {
      const submitData: ProjectsExportCommentsParams = {
        pages: isExportAllPages ? data : selectedPages,
        statuses: selectedCommentsStatuses,
      };

      await requestFn({
        args: submitData,
        successMessage: 'Exported successfully',
      });

      handleClose();
    }
  }, [data, isExportAllPages, requestFn, selectedCommentsStatuses, selectedPages]);

  return (
    <>
      <Button color="primary" size="large" onClick={handleOpen}>
        Export
      </Button>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogContent>
          <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
            <Grid item sx={{ flexGrow: 1 }}>
              <Typography variant="h5">Export params</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={handleClose}>
                <TimesIcon
                  sx={(theme) => ({
                    color: theme.palette.mode === 'dark' ? colors.white : colors.black,
                  })}
                />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {!data && !error && (
              <Grid item xs={12}>
                <Loader height="70vh" />
              </Grid>
            )}
            {!!error && (
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="text.error">
                  Failed to fetch project data
                </Typography>
              </Grid>
            )}
            {!!data && (
              <>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch checked={isExportAllPages} onChange={handleSwitchChange} />}
                      label="Export all pages"
                    />
                  </FormGroup>
                  <Collapse in={!isExportAllPages}>
                    <FormControl component="fieldset" variant="standard" sx={{ mt: 2 }}>
                      <FormLabel component="legend">Select pages</FormLabel>
                      <FormGroup>
                        {data.map((item) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedPages.includes(item)}
                                onChange={handleSelectedPagesChange}
                                name={item}
                              />
                            }
                            label={item}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  </Collapse>
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">Select comments status</FormLabel>
                    <FormGroup>
                      {COMMENTS_STATUSES.map((item) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedCommentsStatuses.includes(item.value)}
                              onChange={handleSelectedStatusesChange}
                              name={item.value}
                            />
                          }
                          label={item.label}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth={false}
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            fullWidth={false}
            color="primary"
            onClick={handleSubmit}
            disabled={isLoading}
            endIcon={isLoading && <Loader inButton />}
          >
            Export
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExportParamsDialog;
