import { Box, Typography } from '@mui/material';
import { VectorPlaceholderIcon } from 'components/icons';

interface Props {
  message?: boolean;
}

const ProjectsPlaceholder = (props: Props): JSX.Element => {
  const { message } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <VectorPlaceholderIcon
        sx={(theme) => ({
          width: '100%',
          height: 'auto',
          color: theme.palette.action.active,
          maxHeight: '50vw',
          my: 3,
        })}
      />
      <Typography variant="subtitle1" paragraph color="text.primary">
        {message || 'No matching data found'}
      </Typography>
    </Box>
  );
};

export default ProjectsPlaceholder;
