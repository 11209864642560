import { useCallback, useMemo } from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { ProjectsItemData } from 'types/Projects';
import { MembershipItemData } from 'types/Membership';
import { useUserData, useSnackbarNotification } from 'hooks';
import ActionsMenu from './ActionsMenu';
import RemoveButton from './RemoveButton';

interface Props {
  type: 'owner' | 'pending' | 'accepted';
  project: ProjectsItemData;
  name: string;
  email: string | undefined;
  avatar?: string;
  membership?: MembershipItemData;
  color?: string;
  closeSettingsDialog?: () => void;
}

const MembersListItem = (props: Props): JSX.Element | null => {
  const { project, email, membership, avatar, name, type, color, closeSettingsDialog } = props;
  const { userData } = useUserData();
  const { setSnackbarNotification } = useSnackbarNotification();

  const handleEmailClick = useCallback(async () => {
    if (email) {
      await navigator.clipboard.writeText(email);
    }

    setSnackbarNotification({ message: 'Email is copied to clipboard', severity: 'info' });
  }, [email, setSnackbarNotification]);

  const secondaryAction = useMemo(() => {
    switch (type) {
      case 'accepted': {
        return (
          <ActionsMenu
            project={project}
            membershipData={membership as MembershipItemData}
            closeSettingsDialog={closeSettingsDialog}
          />
        );
      }
      case 'pending': {
        if (userData?._id === project.owner?._id) {
          return (
            <RemoveButton project={project} membershipData={membership as MembershipItemData} />
          );
        }

        return undefined;
      }
      default: {
        return undefined;
      }
    }
  }, [closeSettingsDialog, membership, project, type, userData?._id]);

  return (
    <ListItem secondaryAction={secondaryAction} disableGutters>
      <ListItemAvatar>
        <Avatar alt={name} src={avatar} sx={{ bgcolor: color }}>
          {name.charAt(0).toUpperCase()}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        onClick={handleEmailClick}
        primary={name}
        secondary={email}
        primaryTypographyProps={{ sx: { fontWeight: 600 }, noWrap: true }}
        secondaryTypographyProps={{ noWrap: true }}
        sx={(theme) => ({
          [theme.breakpoints.up('xs')]: {
            maxWidth: '255px',
          },
          [theme.breakpoints.up('sm')]: {
            maxWidth: '100%',
          },
          '&:hover': {
            cursor: 'pointer',
          },
        })}
      />
    </ListItem>
  );
};

export default MembersListItem;
