import { Dialog, DialogContent, Grid, IconButton, Popover, Typography } from '@mui/material';
import { ProjectSettingsTabsEnum } from 'types/common';
import colors from 'theme/colors';
import { TimesIcon } from 'components/icons';
import { ProjectsItemData } from 'types/Projects';
import ProjectTabs from './ProjectTabs';

interface Props {
  variant: 'dialog' | 'menu';
  projectId: string;
  projectData?: ProjectsItemData;
  isOpen: boolean;
  anchorEl?: HTMLElement | null;
  onClose: () => void;
  activeTab?: ProjectSettingsTabsEnum;
}

const ProjectSettings = (props: Props): JSX.Element | null => {
  const { projectId, activeTab, onClose, isOpen, projectData, variant, anchorEl } = props;

  const handleClose = () => {
    onClose();
  };

  if (variant === 'menu') {
    return (
      <Popover
        id="project-settings"
        anchorEl={anchorEl}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isOpen}
        onClose={handleClose}
        sx={{ mt: 1 }}
        PaperProps={{
          sx: {
            width: '400px',
            p: 3,
          },
        }}
      >
        <Typography variant="h5" color="text.primary" sx={{ mb: 1 }}>
          Settings
        </Typography>
        <ProjectTabs
          projectId={projectId}
          isOpen={isOpen}
          onClose={handleClose}
          activeTab={activeTab}
          projectData={projectData}
        />
      </Popover>
    );
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent>
        <Grid
          container
          alignItems="center"
          sx={{
            mb: 2,
          }}
        >
          <Grid item sx={{ flexGrow: 1 }}>
            <Typography variant="h5" color="text.primary">
              Settings
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <TimesIcon
                sx={(theme) => ({
                  color: theme.palette.mode === 'dark' ? colors.white : colors.black,
                })}
              />
            </IconButton>
          </Grid>
        </Grid>
        <ProjectTabs
          projectId={projectId}
          isOpen={isOpen}
          onClose={handleClose}
          activeTab={activeTab}
          projectData={projectData}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ProjectSettings;
