import { useCallback } from 'react';
import { SnackbarNotificationData } from 'types/common';
import generalActions from 'store/actions/generalActions';
import useStore from './useStore';

const useSnackbarNotification = (): {
  setSnackbarNotification: (data: SnackbarNotificationData | null) => void;
  snackbarNotification: SnackbarNotificationData | null;
} => {
  const {
    state: {
      general: { snackbarNotification },
    },
    dispatch,
  } = useStore();

  const setSnackbarNotification = useCallback(
    (data: SnackbarNotificationData | null) => {
      dispatch(generalActions.setSnackbarNotification(data));
    },
    [dispatch]
  );

  return { snackbarNotification, setSnackbarNotification };
};

export default useSnackbarNotification;
