const publicRoutes = {
  index: {
    name: 'Index',
    link: '/',
  },
  forgotPassword: {
    name: 'Restore password',
    link: '/restore-password',
  },
  signInConfirmCode: {
    name: 'Sign in with code',
    link: '/sign-in/confirm-code',
  },
  register: {
    name: 'Register',
    link: '/sign-up',
  },
  signIn: {
    name: 'Log in',
    link: '/sign-in',
  },
};

const privateRoutes = {
  projects: {
    name: 'Projects',
    link: '/projects',
  },
  pushNotifications: {
    name: 'Push notifications',
    link: '/push-notifications',
  },
  billing: {
    name: 'Billing',
    link: '/billing',
  },
  applyLicenseKey: {
    name: 'Add license key',
    link: '/billing/add-license-key',
  },
  settings: {
    name: 'Settings',
    link: '/settings',
  },
  changePassword: {
    name: 'Change password',
    link: '/settings/change-password',
  },
  slackConnected: {
    name: 'Slack connected',
    link: '/slack-connected',
  },
  connectAtlassian: {
    name: 'Connect Atlassian',
    link: '/connect-atlassian',
    as: (projectId: string): string => `/connect-atlassian?projectId=${projectId}`,
  },
  atlassianConnected: {
    name: 'Atlassian connected',
    link: '/atlassian-connected',
  },
  connectGithub: {
    name: 'Connect Github',
    link: '/connect-github',
    as: (projectId: string): string => `/connect-github?projectId=${projectId}`,
  },
  githubConnected: {
    name: 'Github connected',
    link: '/github-connected',
  },
  connectSlack: {
    name: 'Connect Slack',
    link: '/connect-slack',
    as: (projectId: string): string => `/connect-slack?projectId=${projectId}`,
  },
  projectDetails: {
    name: 'Project info',
    link: '/project/:id',
    as: (id: string): string => `/project/${id}`,
  },
  shareToWebProject: {
    name: 'Shared project info',
    link: '/project/share/:id',
    as: (id: string): string => `/project/share/${id}`,
  },
};

const appLinks = {
  ...publicRoutes,
  ...privateRoutes,
};

const projectPublicUrl = (projectId: string): string =>
  `${process.env.REACT_APP_SELF_URL}${appLinks.shareToWebProject.as(projectId)}`;

const connectGithubRepoReturnUrl = `${process.env.REACT_APP_SELF_URL}${appLinks.githubConnected.link}`;

const connectGithubRepoUrl =
  `https://github.com/login/oauth/authorize` +
  `?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}` +
  `&redirect_uri=${connectGithubRepoReturnUrl}&scope=repo`;

const connectAtlassianUrl = (projectId: string): string =>
  'https://auth.atlassian.com/authorize' +
  '?audience=api.atlassian.com' +
  `&client_id=${process.env.REACT_APP_ATLASSIAN_CLIENT_ID}` +
  '&scope=write:jira-work read:jira-work offline_access' +
  `&redirect_uri=${process.env.REACT_APP_SELF_URL}/atlassian-connected` +
  `&state=${projectId}` +
  '&response_type=code' +
  '&prompt=consent';

export {
  appLinks,
  projectPublicUrl,
  connectGithubRepoReturnUrl,
  connectGithubRepoUrl,
  connectAtlassianUrl,
};
