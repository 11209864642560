import { ProjectsActions, ProjectsActionsEnum, StoreProjectsState } from '../types/projectsTypes';
import { GlobalActions, GlobalActionsEnum } from '../types/globalTypes';

const initialState: StoreProjectsState = {
  projects: undefined,
  mentionSuggestionOptions: [],
  activeProject: undefined,
  activeProjectAccessRights: {
    accessRight: 'unauthorized',
    canAddComments: false,
  },
};

const projectsReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: ProjectsActions | GlobalActions
): StoreProjectsState => {
  switch (action.type) {
    case ProjectsActionsEnum.SET_PROJECTS:
      return { ...state, projects: action.payload };
    case ProjectsActionsEnum.SET_MENTION_SUGGESTION_OPTIONS:
      return { ...state, mentionSuggestionOptions: action.payload };
    case ProjectsActionsEnum.SET_ACTIVE_PROJECT:
      return { ...state, activeProject: action.payload };
    case ProjectsActionsEnum.SET_PROJECT_ACCESS_RIGHTS:
      return { ...state, activeProjectAccessRights: action.payload };
    case GlobalActionsEnum.RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default projectsReducer;
