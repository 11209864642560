const { REACT_APP_SELF_URL, REACT_APP_SLACK_CLIENT_ID } = process.env;

const CONNECT_SLACK_URL =
  'https://slack.com/oauth/v2/authorize?' +
  'scope=incoming-webhook,chat:write&' +
  `client_id=${REACT_APP_SLACK_CLIENT_ID}&` +
  `redirect_uri=${REACT_APP_SELF_URL}/slack-connected`;

const STORE_LINK =
  'https://chrome.google.com/webstore/detail/greenlight/kdmfnoihcnkaipoffinheahhclgmfdie';

const SIDE_OFFSET = {
  xs: 24,
  sm: 32,
  md: 40,
  lg: 64,
  xl: 116,
  xxl: 116,
};

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
  xxl: 2560,
};

const INPUTS_LIMITS = {
  NAME: 70,
  PASSWORD: 50,
};

const DRAWER_WIDTH = 240;
const TOOLBAR_HEIGHT = 64;

const HIGHLIGHT_TIMER_MS = 5000;
const TRIAL_COMMENTS_ALLOWED = 10;

export {
  STORE_LINK,
  SIDE_OFFSET,
  breakpoints,
  INPUTS_LIMITS,
  DRAWER_WIDTH,
  CONNECT_SLACK_URL,
  TOOLBAR_HEIGHT,
  HIGHLIGHT_TIMER_MS,
  TRIAL_COMMENTS_ALLOWED,
};
