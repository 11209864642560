import { useLocation } from 'react-router-dom';

const useSearchParam = <T>(): Partial<T> => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  return Object.fromEntries(searchParams) as unknown as Partial<T>;
};

export default useSearchParam;
