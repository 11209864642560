import { SvgIcon, SvgIconProps } from '@mui/material';

const EyeOpenIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.5566 9.72656C14.502 7.65625 12.3926 6.25 10.0098 6.25C7.60742 6.25 5.49805 7.65625 4.44336 9.72656C4.4043 9.80469 4.38477 9.92188 4.38477 10.0195C4.38477 10.0977 4.4043 10.2148 4.44336 10.293C5.49805 12.3633 7.60742 13.75 10.0098 13.75C12.3926 13.75 14.502 12.3633 15.5566 10.293C15.5957 10.2148 15.6152 10.0977 15.6152 10C15.6152 9.92188 15.5957 9.80469 15.5566 9.72656ZM10.0098 12.8125C8.44727 12.8125 7.19727 11.5625 7.19727 10C7.19727 8.45703 8.44727 7.1875 10.0098 7.1875C11.5527 7.1875 12.8223 8.45703 12.8223 10V10.0195C12.8223 11.5625 11.5527 12.8125 10.0098 12.8125ZM10.0098 8.125C9.85352 8.14453 9.63867 8.16406 9.50195 8.20312C9.59961 8.33984 9.69727 8.59375 9.69727 8.76953C9.69727 9.27734 9.26758 9.6875 8.75977 9.6875C8.58398 9.6875 8.33008 9.60938 8.19336 9.51172C8.1543 9.64844 8.13477 9.86328 8.13477 10C8.13477 11.0352 8.97461 11.875 10.0098 11.875C11.0449 11.875 11.8848 11.0352 11.8848 10C11.8848 8.96484 11.0449 8.125 10.0098 8.125Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default EyeOpenIcon;
