import { useDispatch, useSelector } from 'react-redux';
import { StoreRootState } from 'store';
import { Dispatch } from 'redux';

const useStore = (): { state: StoreRootState; dispatch: Dispatch } => {
  const state = useSelector((store: StoreRootState) => store);
  const dispatch = useDispatch();

  return {
    state,
    dispatch,
  };
};

export default useStore;
