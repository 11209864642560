import { ReactNode } from 'react';
import { Box, Container } from '@mui/material';
import { Header } from './index';
import { TOOLBAR_HEIGHT } from '../constants/common';
import colors from '../theme/colors';

interface Props {
  children: ReactNode;
  title?: string;
  backUrl?: string;
}

const PrivateRouteLayout = (props: Props): JSX.Element | null => {
  const { children, backUrl, title } = props;

  return (
    <Box component="main">
      <Header title={title} backUrl={backUrl} />
      <Box sx={(theme) => ({ ...theme.mixins.toolbar, height: `${TOOLBAR_HEIGHT}px` })} />
      <Container
        sx={(theme) => ({
          overflow: 'auto',
          py: 5,
          bgcolor:
            theme.palette.mode === 'dark' ? theme.palette.background.paper : colors.lightGrey3,
        })}
      >
        {children}
      </Container>
    </Box>
  );
};

export default PrivateRouteLayout;
