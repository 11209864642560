import { SvgIcon, SvgIconProps } from '@mui/material';

const EyeClosedIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 12.8027C8.51562 12.8027 7.30469 11.6699 7.20703 10.2051L5.15625 8.62305C4.88281 8.97461 4.62891 9.32617 4.43359 9.7168C4.39453 9.79492 4.375 9.91211 4.375 10.0098C4.375 10.0879 4.39453 10.2051 4.43359 10.2832C5.48828 12.3535 7.59766 13.7402 10 13.7402C10.5078 13.7402 11.0156 13.6621 11.5039 13.5449L10.5078 12.7637C10.3516 12.7832 10.1367 12.8027 10 12.8027ZM16.1133 13.9551L13.9648 12.2754C14.5117 11.8066 15.2148 10.9277 15.5469 10.2832C15.5859 10.2051 15.6055 10.0879 15.6055 9.99023C15.6055 9.91211 15.5859 9.79492 15.5469 9.7168C14.4922 7.64648 12.3828 6.24023 10 6.24023C9.14062 6.25977 7.85156 6.57227 7.10938 6.98242L4.62891 5.06836C4.58984 5.0293 4.49219 4.99023 4.43359 4.99023C4.35547 4.99023 4.23828 5.04883 4.19922 5.12695L3.80859 5.61523C3.76953 5.6543 3.75 5.75195 3.75 5.81055C3.75 5.88867 3.78906 6.00586 3.86719 6.04492L15.3516 14.9316C15.3906 14.9707 15.4883 15.0098 15.5469 15.0098C15.625 15.0098 15.7422 14.9512 15.8008 14.873L16.1719 14.3848C16.2109 14.3457 16.25 14.248 16.25 14.1895C16.25 14.1113 16.1914 13.9941 16.1133 13.9551ZM12.5391 11.1816L11.7578 10.5762C11.8164 10.4199 11.8555 10.166 11.875 9.99023C11.875 9.99023 11.875 9.99023 11.875 9.9707C11.875 8.95508 11.0352 8.11523 10.0195 8.11523C9.86328 8.11523 9.62891 8.1543 9.49219 8.19336C9.58984 8.33008 9.66797 8.58398 9.6875 8.74023C9.66797 8.79883 9.66797 8.89648 9.64844 8.93555L8.20312 7.8418C8.63281 7.4707 9.43359 7.19727 10 7.17773C11.543 7.17773 12.8125 8.44727 12.8125 9.99023C12.8125 10.4199 12.6953 10.8105 12.5391 11.1816Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default EyeClosedIcon;
