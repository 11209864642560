import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { appLinks } from './routes';

interface Props extends RouteProps {
  isAuthenticated: boolean;
}

const PublicRoute: React.FC<Props> = ({ isAuthenticated, ...rest }: Props) =>
  !isAuthenticated ? <Route {...rest} /> : <Redirect to={appLinks.projects.link} />;

export default PublicRoute;
