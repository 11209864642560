import { SvgIcon, SvgIconProps } from '@mui/material';

const AppleIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.5266 11.5216C16.5266 10.0351 17.2048 8.95038 18.5213 8.1067C17.7633 7.02195 16.6463 6.4595 15.1702 6.33897C13.734 6.21844 12.1782 7.14248 11.6197 7.14248C11.0213 7.14248 9.66489 6.37914 8.58777 6.37914C6.35372 6.41932 4 8.14687 4 11.7225C4 12.7671 4.15957 13.8518 4.55851 14.9767C5.07713 16.4632 6.91223 20.079 8.82713 19.9987C9.82447 19.9987 10.5426 19.2755 11.859 19.2755C13.1356 19.2755 13.7739 19.9987 14.891 19.9987C16.8457 19.9987 18.5213 16.7043 19 15.2178C16.4069 13.9723 16.5266 11.602 16.5266 11.5216ZM14.2926 4.93282C15.3697 3.6472 15.25 2.44193 15.25 2C14.2926 2.08035 13.1755 2.68299 12.5372 3.40615C11.8191 4.20966 11.4202 5.21405 11.5 6.29879C12.5372 6.37914 13.4947 5.85686 14.2926 4.93282Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default AppleIcon;
