import { useMemo, MouseEvent } from 'react';
import { Avatar, avatarClasses, AvatarGroup } from '@mui/material';
import { ProjectsItemData } from 'types/Projects';
import { getUserInitials } from 'helpers/common';

interface Props {
  project: ProjectsItemData;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  size?: number;
}

const MembersAvatarGroup = (props: Props): JSX.Element | null => {
  const { project, size = 30, onClick } = props;

  const avatarSize = useMemo(
    () => ({
      width: size,
      height: size,
    }),
    [size]
  );

  return (
    <AvatarGroup
      total={project.members.length + 1}
      max={4}
      onClick={onClick}
      sx={() => ({
        [`& .${avatarClasses.root}`]: {
          fontSize: '16px',
          ...avatarSize,
        },
        cursor: onClick ? 'pointer' : undefined,
      })}
    >
      <Avatar alt={project.owner?.username} src={project.owner?.avatar?.mediaUrl}>
        {getUserInitials(project.owner?.username || '')}
      </Avatar>
      {project.members.map((m) => (
        <Avatar
          key={m._id}
          alt={m.member?.username}
          src={m.member?.avatar?.mediaUrl}
          sx={{
            bgcolor: m.color,
          }}
        >
          {m.member ? getUserInitials(m.member.username) : getUserInitials(m.recipient)}
        </Avatar>
      ))}
    </AvatarGroup>
  );
};

export default MembersAvatarGroup;
