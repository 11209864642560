import { SvgIcon, SvgIconProps } from '@mui/material';

const UndoIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 12L10.4828 15L16 9" stroke="currentColor" strokeWidth="1.5" />
    <path
      d="M4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C10.3314 4 8.78211 4.51085 7.5 5.38468"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M7.83043 2.04679L7.34063 5.92108L11.0631 7.1015"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </SvgIcon>
);

export default UndoIcon;
