import {
  createTheme,
  Theme,
  PaletteMode,
  inputLabelClasses,
  lighten,
  buttonClasses,
  outlinedInputClasses,
  switchClasses,
} from '@mui/material';
import colors from './colors';

export const BORDER_RADIUS = 6;

const createMuiTheme = (mode: PaletteMode): Theme => {
  const cardBorderColor = mode === 'dark' ? colors.grey : colors.lightGrey4;

  return createTheme({
    typography: {
      htmlFontSize: 16,
      fontFamily: '"PSans0.8", sans-serif',
      h2: {
        fontSize: 32,
        fontWeight: 600,
      },
      h5: {
        fontSize: 24,
        fontWeight: 600,
      },
      subtitle1: {
        fontSize: 18,
        fontWeight: 600,
      },
      subtitle2: {
        fontSize: 18,
        fontWeight: 400,
      },
      body1: {
        fontSize: 16,
      },
      body2: {
        fontSize: 14,
        fontWeight: 600,
      },
      button: {
        fontSize: 16,
      },
      caption: {
        fontSize: 12,
      },
    },
    palette: {
      mode,
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.grey,
      },
      text: {
        primary: mode === 'dark' ? colors.white : colors.darkGrey,
      },
      background: {
        default: mode === 'light' ? colors.lightGrey3 : '#121212',
      },
      warning: {
        main: colors.yellow,
      },
      error: {
        main: colors.red,
      },
      info: {
        main: colors.primary,
      },
      success: {
        main: colors.green,
      },
    },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          fullWidth: true,
        },
        styleOverrides: {
          root: {
            borderRadius: BORDER_RADIUS,
            textTransform: 'none',
            fontWeight: 600,
            padding: '8px 24px',
            lineHeight: '24px',
            fontSize: '16px',
            minWidth: 'unset',
          },
          sizeSmall: {
            padding: '4px 8px',
          },
          containedPrimary: {
            boxShadow: 'unset',
            '&:hover, &:focus': {
              boxShadow: 'unset',
            },
            [`&.${buttonClasses.disabled}`]: {
              backgroundColor: lighten(colors.primary, 0.3),
              color: colors.white,
            },
          },
          outlinedSecondary: {
            color: mode === 'dark' ? colors.white : colors.black,
            borderColor: mode === 'dark' ? colors.grey : colors.lightGrey,
            backgroundColor: mode === 'dark' ? colors.darkGrey : colors.white,
            '&:hover, &:focus': {
              borderColor: mode === 'dark' ? colors.white : colors.black,
              backgroundColor: mode === 'dark' ? colors.darkGrey : colors.white,
            },
            [`&.${buttonClasses.disabled}`]: {
              backgroundColor: mode === 'dark' ? colors.darkGrey : colors.lightGrey2,
              color: colors.grey,
            },
          },
          outlinedPrimary: {
            color: mode === 'dark' ? colors.white : colors.black,
            border: `1px solid ${colors.lightGrey2}`,
            backgroundColor: mode === 'dark' ? colors.darkGrey : colors.white,
            boxShadow: '0px 1px 4px -1px rgba(1, 13, 26, 0.16)',
            '&:hover, &:focus': {
              borderColor: mode === 'dark' ? colors.white : colors.black,
              backgroundColor: mode === 'dark' ? colors.darkGrey : colors.white,
            },
            [`&.${buttonClasses.disabled}`]: {
              backgroundColor: mode === 'dark' ? colors.darkGrey : colors.lightGrey2,
              color: colors.grey,
              boxShadow: 'unset',
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: `${BORDER_RADIUS}px`,
            color: mode === 'dark' ? colors.white : colors.black,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          fullWidth: true,
          variant: 'outlined',
          InputLabelProps: {
            shrink: true,
          },
          InputProps: {
            notched: false,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            'label + &': {
              marginTop: 32,
            },
          },
          input: {
            fontSize: 16,
            padding: '8px 12px',
            height: 24,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            lineHeight: '24px',
            fontSize: '16px',
          },
          outlined: {
            color: mode === 'dark' ? colors.white : colors.darkGrey,
            [`&.${inputLabelClasses.shrink}`]: {
              transform: 'translate(0, 0) scale(1)',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            '& legend': {
              width: 'auto',
            },
          },
          root: {
            borderRadius: BORDER_RADIUS,
            borderWidth: 1.5,
            background: mode === 'dark' ? 'unset' : colors.white,
            borderColor: colors.lightGrey,
            [`&.${outlinedInputClasses.focused}`]: {
              borderWidth: 1.5,
              [`& .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: colors.grey,
              },
            },
            [`&.${outlinedInputClasses.disabled}`]: {
              background: mode === 'dark' ? 'unset' : colors.lightGrey2,
              [`&.${outlinedInputClasses.notchedOutline}`]: {
                borderColor: mode === 'dark' ? colors.grey : 'transparent',
              },
            },
            '&:hover': {
              [`& .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: colors.grey,
              },
            },
          },
          input: {
            padding: '8px 12px',
            height: 24,
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            padding: 10,
            width: 48,
          },
          track: {
            borderRadius: 4,
            opacity: 'unset',
            background: mode === 'dark' ? colors.darkGrey : colors.white,
            border: `1px solid ${colors.lightGrey2}`,
          },
          thumb: {
            borderRadius: 3,
            boxShadow: 'unset',
            color: colors.lightGrey,
            height: 14,
            width: 14,
          },
          switchBase: {
            padding: 12,
            '&:hover': {
              background: 'unset',
            },
            [`&.${switchClasses.checked}`]: {
              transform: 'translateX(10px)',
              '&:hover': {
                background: 'unset',
              },
              [`& .${switchClasses.thumb}`]: {
                color: colors.white,
              },
              [`&+.${switchClasses.track}`]: {
                opacity: 'unset',
                border: `1px solid ${colors.primary}`,
                backgroundColor: colors.primary,
              },
            },
            [`&.${switchClasses.disabled}`]: {
              [`&+.${switchClasses.track}`]: {
                opacity: '0.5',
              },
            },
          },
        },
      },
      MuiCard: {
        defaultProps: {
          elevation: 0,
        },
        styleOverrides: {
          root: {
            border: `0.5px solid ${cardBorderColor}`,
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: '12px 12px 8px',
          },
          action: {
            margin: 0,
          },
          avatar: {
            marginRight: '8px',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: '8px 12px',
            '&:last-child': { paddingBottom: '12px' },
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: '8px 12px',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: 16,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: BORDER_RADIUS,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            minHeight: '24px',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: 50,
            padding: 8,
            textTransform: 'unset',
            flex: '1 1 auto',
            fontWeight: 600,
            fontSize: '16px',
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          extended: {
            fontWeight: 600,
            textTransform: 'unset',
            padding: '0 24px',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontSize: 12,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: 12,
          },
        },
      },
    },
  });
};

export default createMuiTheme;
