import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import { appLinks } from 'routes/routes';
import PasswordInput, { PasswordInputProps } from './PasswordInput';

const PasswordInputWithForgot = (props: PasswordInputProps): JSX.Element => {
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <PasswordInput {...props} />
      <Link
        component={NavLink}
        underline="hover"
        to={appLinks.signInConfirmCode.link}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        Forgot?
      </Link>
    </Box>
  );
};

export default PasswordInputWithForgot;
