import {
  ProjectsExportCommentsParams,
  ProjectsInviteMemberParams,
  ProjectsInviteMemberResponse,
  ProjectsItemCommentsCountData,
  ProjectsItemCommentsCountResponse,
  ProjectsItemCreateParams,
  ProjectsItemData,
  ProjectsItemPagesData,
  ProjectsItemPagesResponse,
  ProjectsItemResponse,
  ProjectsItemUpdateParams,
  ProjectsListData,
  ProjectsListResponse,
  ProjectsTransferOwnershipParams,
} from 'types/Projects';
import { ValidationData, ValidationResponse } from 'types/common';
import ENDPOINTS from './endpoints';
import { apiClient } from './apiClient';
import { MembershipItemData } from '../types/Membership';

const endpoint = ENDPOINTS.PROJECTS;

const urls = {
  getList: endpoint,
  getItem: (id: string): string => `${endpoint}/${id}`,
  getItemPages: (id: string): string => `${endpoint}/${id}/pages`,
  getItemCommentsCount: (id: string): string => `${endpoint}/${id}/comments`,
  createItem: endpoint,
  updateItem: (id: string): string => `${endpoint}/${id}`,
  deleteItem: (id: string): string => `${endpoint}/${id}`,
  inviteUserToProject: (id: string): string => `${endpoint}/${id}/invite-member`,
  removeUserFromProject: (id: string): string => `${endpoint}/revoke-membership/${id}`,
  transferOwnership: (id: string): string => `${endpoint}/${id}/transfer-ownership`,
  exportCommentsToGithub: (id: string): string => `${endpoint}/${id}/export-comments-to-github`,
  exportCommentsToAtlassian: (id: string): string => `${endpoint}/${id}/export-comments-to-jira`,
  exportCommentsAsMarkdown: (id: string): string => `${endpoint}/${id}/export-project-as-markdown`,
};

const getList = (): ProjectsListResponse =>
  apiClient.get<ProjectsListData>(urls.getList).then((res) => res.data);

// optional public request
const getItem = (id: string): ProjectsItemResponse =>
  apiClient.get<ProjectsItemData>(urls.getItem(id)).then((res) => res.data);

const getItemPages = (id: string): ProjectsItemPagesResponse =>
  apiClient.get<ProjectsItemPagesData>(urls.getItemPages(id)).then((res) => res.data);

const getItemCommentsCount = (id: string): ProjectsItemCommentsCountResponse =>
  apiClient
    .get<ProjectsItemCommentsCountData>(urls.getItemCommentsCount(id))
    .then((res) => res.data);

const createItem = ({
  data,
  onUploadProgress,
}: {
  data: ProjectsItemCreateParams;
  onUploadProgress?: (progressEvent: { loaded: number; total: number }) => void;
}): ProjectsItemResponse => {
  const formData = new FormData();
  const keys = Object.keys(data) as (keyof ProjectsItemCreateParams)[];

  keys.forEach((k) => {
    if (data[k]) {
      if (k === 'screenshot' && data.screenshot) {
        formData.append(k, data.screenshot, data.screenshot.name);
      } else {
        formData.append(k, data[k].toString());
      }
    }
  });

  return apiClient
    .post<ProjectsItemData>(urls.createItem, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
    })
    .then((res) => res.data);
};

const updateItem = (id: string, data: ProjectsItemUpdateParams): ProjectsItemResponse =>
  apiClient.put<ProjectsItemData>(urls.updateItem(id), data).then((res) => res.data);

const deleteItem = (id: string): ValidationResponse =>
  apiClient.delete<ValidationData>(urls.deleteItem(id)).then((res) => res.data);

const inviteUserToProject = (
  id: string,
  data: ProjectsInviteMemberParams
): ProjectsInviteMemberResponse =>
  apiClient.post<MembershipItemData>(urls.inviteUserToProject(id), data).then((res) => res.data);

const removeUserFromProject = (membershipId: string): ValidationResponse =>
  apiClient
    .delete<ValidationData>(urls.removeUserFromProject(membershipId))
    .then((res) => res.data);

const transferOwnership = (
  id: string,
  data: ProjectsTransferOwnershipParams
): ProjectsItemResponse =>
  apiClient.post<ProjectsItemData>(urls.transferOwnership(id), data).then((res) => res.data);

const exportCommentsToGithub = (
  id: string,
  data: ProjectsExportCommentsParams
): ValidationResponse =>
  apiClient.post<ValidationData>(urls.exportCommentsToGithub(id), data).then((res) => res.data);

const exportCommentsToAtlassian = (
  id: string,
  data: ProjectsExportCommentsParams
): ValidationResponse =>
  apiClient.post<ValidationData>(urls.exportCommentsToAtlassian(id), data).then((res) => res.data);

const exportCommentsAsMarkdown = (id: string): Promise<string> =>
  apiClient.get<string>(urls.exportCommentsAsMarkdown(id)).then((res) => res.data);

export default {
  urls,
  getList,
  getItem,
  getItemPages,
  createItem,
  updateItem,
  deleteItem,
  getItemCommentsCount,
  inviteUserToProject,
  removeUserFromProject,
  transferOwnership,
  exportCommentsToGithub,
  exportCommentsToAtlassian,
  exportCommentsAsMarkdown,
};
