import { Divider, Grid } from '@mui/material';
import { ProjectsItemData } from 'types/Projects';
import InviteForm from './InviteForm';
import MembersList from './MembersList';
import ShareToWeb from './ShareToWeb';

interface Props {
  project: ProjectsItemData;
  closeSettingsDialog: () => void;
}

const MembersTab = (props: Props): JSX.Element | null => {
  const { project, closeSettingsDialog } = props;

  return (
    <Grid container direction="column">
      <Grid item xs={12} sx={{ mb: 3 }}>
        <ShareToWeb project={project} />
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Divider />
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <InviteForm project={project} />
      </Grid>
      <Grid item xs={12}>
        <MembersList project={project} closeSettingsDialog={closeSettingsDialog} />
      </Grid>
    </Grid>
  );
};

export default MembersTab;
