import { SvgIcon, SvgIconProps } from '@mui/material';

const ChromeIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.23033 10.7676C8.84389 8.99514 10.62 7.93166 12.5253 8.06057L19.1452 8.41506C18.4671 7.06154 17.4014 5.90138 15.9806 5.09571C14.7212 4.35449 13.3326 4 11.9763 4C9.61892 4.03223 7.29385 5.06348 5.77609 6.99709L8.23033 10.7676ZM9.29599 11.9922C9.29599 13.5069 10.4908 14.6993 12.0086 14.6993C13.494 14.6993 14.6889 13.5069 14.6889 11.9922C14.6889 10.5098 13.494 9.31741 12.0086 9.31741C10.4908 9.31741 9.29599 10.5098 9.29599 11.9922ZM19.4682 9.12405L14.9472 9.34964C16.1743 10.7998 16.2066 12.8624 15.1732 14.4415L11.5565 19.9845C13.0419 20.0811 14.592 19.7266 15.9806 18.921C19.4682 16.9229 20.8568 12.7334 19.4682 9.12405ZM8.29492 13.5391L5.29171 7.64162C4.4521 8.89846 4 10.3809 4 11.9922C4 15.9883 6.90634 19.3077 10.7492 19.8878L12.8159 15.8917C10.9429 16.2139 9.16682 15.2149 8.29492 13.5391Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ChromeIcon;
