import { useCallback, useEffect, useMemo } from 'react';
import { mutate } from 'swr';
import { Box, Button, Grid, IconButton, Popover, Typography } from '@mui/material';
import { Loader } from 'components';
import colors from 'theme/colors';
import { useApiRequest } from 'hooks';
import projectsApi from 'api/projectsApi';
import { ProjectsItemData } from 'types/Projects';
import { TimesIcon } from 'components/icons';
import { MemberActionDialogType, ValidationResponse } from 'types/common';
import { MembershipItemData } from 'types/Membership';

interface Props {
  type: MemberActionDialogType;
  membershipData: MembershipItemData;
  project: ProjectsItemData;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  closeSettingsDialog?: () => void;
}

const ActionDialog = (props: Props): JSX.Element => {
  const { anchorEl, onClose, type, project, membershipData, closeSettingsDialog } = props;
  const { requestFn, isLoading } = useApiRequest(
    type === 'transferOwnership'
      ? () =>
          projectsApi.transferOwnership(project._id, {
            newOwnerId: membershipData.member!._id,
          }) as unknown as ValidationResponse
      : () => projectsApi.removeUserFromProject(membershipData._id)
  );

  const texts = useMemo(() => {
    switch (type) {
      case 'transferOwnership': {
        return {
          header: 'Confirm transfer',
          title: `Are you sure you want to transfer ownership of ${project.title}?`,
          subtitle: `You will no longer be owner of ${project.title}. You still be in the project as collaborator.`,
          submitButtonText: 'Transfer',
          successMessage: 'Ownership is transferred successfully',
        };
      }
      case 'leaveProject': {
        return {
          header: 'Confirm leave',
          title: `Are you sure you want to leave ${project.title}?`,
          subtitle: `You will no longer be able to view any comments on ${project.title}`,
          submitButtonText: 'Leave',
          successMessage: `You left ${project.title}`,
        };
      }
      default: {
        return {
          header: 'Confirm remove',
          title: `Are you sure you want to remove ${
            membershipData.member?.username || membershipData.recipient
          }?`,
          subtitle: `${
            membershipData.member?.username || membershipData.recipient
          } will no longer be able to view any comments on ${project.title}`,
          submitButtonText: 'Remove',
          successMessage: `${membershipData.member?.username || membershipData.recipient} removed`,
        };
      }
    }
  }, [project.title, type, membershipData]);

  const handleSubmit = useCallback(async () => {
    await requestFn({
      successMessage: texts.successMessage,
    });

    if (type === 'leaveProject') {
      await mutate(projectsApi.urls.getList);
      onClose();
      if (closeSettingsDialog) closeSettingsDialog();
    } else {
      await mutate(projectsApi.urls.getItem(project._id));
      onClose();
    }
  }, [closeSettingsDialog, onClose, project._id, requestFn, texts.successMessage, type]);

  const open = Boolean(anchorEl);
  const id = open ? `user-${membershipData._id}-popover` : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={isLoading ? undefined : onClose}
      BackdropProps={{
        invisible: false,
      }}
      sx={{
        marginTop: 1,
      }}
    >
      <Box sx={{ p: 2, maxWidth: 400 }}>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center">
              <Grid item>
                <Typography variant="subtitle1">{texts.header}</Typography>
              </Grid>
              <Grid item sx={{ ml: 2 }}>
                <IconButton onClick={onClose} disabled={isLoading}>
                  <TimesIcon
                    sx={(theme) => ({
                      color: theme.palette.mode === 'dark' ? colors.white : colors.black,
                    })}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography color="text.primary" gutterBottom>
              {texts.title}
            </Typography>
            <Typography color="text.secondary">{texts.subtitle}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end" wrap="nowrap">
              <Grid item xs="auto">
                <Button
                  fullWidth={false}
                  variant="outlined"
                  color="secondary"
                  onClick={onClose}
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs="auto" sx={{ ml: 1 }}>
                <Button
                  fullWidth={false}
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isLoading}
                  endIcon={isLoading && <Loader inButton />}
                >
                  {texts.submitButtonText}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );
};

export default ActionDialog;
