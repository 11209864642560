import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const ArrowRightIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9 18L15 12.3158L9 6" stroke="currentColor" strokeWidth="1.5" />
  </SvgIcon>
);

export default ArrowRightIcon;
