import { SvgIcon, SvgIconProps } from '@mui/material';

const DownloadIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 4V14.1893L6.53033 9.46967L5.46967 10.5303L10.7312 15.7918C11.4357 16.4963 12.5854 16.4713 13.2586 15.7369L18.0529 10.5068L16.9471 9.49321L12.75 14.0719V4H11.25ZM6.75 17V15H5.25V17C5.25 18.5188 6.48122 19.75 8 19.75H16C17.5188 19.75 18.75 18.5188 18.75 17V15H17.25V17C17.25 17.6904 16.6904 18.25 16 18.25H8C7.30964 18.25 6.75 17.6904 6.75 17Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default DownloadIcon;
