import { useState, MouseEvent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, ListItemText, Menu, MenuItem, Typography, Avatar, Box } from '@mui/material';
import { getUserInitials } from 'helpers/common';
import { appLinks } from 'routes/routes';
import { deleteApiAuthorizationHeader } from 'api/apiClient';
import { removeToken } from 'helpers/cookies';
import { ArrowDownIcon } from 'components/icons';
import { useStore } from 'hooks';
import globalActions from 'store/actions/globalActions';

const UserMenu = (): JSX.Element | null => {
  const {
    state: {
      auth: { userData },
    },
    dispatch,
  } = useStore();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBillingClick = () => {
    handleClose();
    history.push(appLinks.billing.link);
  };
  const handleSettingsClick = () => {
    handleClose();
    history.push(appLinks.settings.link);
  };

  const handleLogoutClick = useCallback(() => {
    deleteApiAuthorizationHeader();
    removeToken();
    dispatch(globalActions.resetStore());

    history.push(appLinks.index.link);
  }, [dispatch, history]);

  return (
    <>
      <Grid
        container
        columnGap={1}
        wrap="nowrap"
        alignItems="center"
        onClick={handleClick}
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        <Grid item>
          <Avatar
            src={userData?.avatar?.mediaUrl}
            alt={userData?.username}
            sx={{
              width: 32,
              height: 32,
            }}
          >
            {getUserInitials(userData?.username || '')}
          </Avatar>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Typography sx={{ fontWeight: 600, maxWidth: '100px' }} noWrap>
            {userData?.username}
          </Typography>
        </Grid>
        <Grid item>
          <Box sx={{ display: 'flex' }}>
            <ArrowDownIcon
              sx={{ transform: anchorEl ? 'rotate(180deg)' : undefined, fill: 'none' }}
            />
          </Box>
        </Grid>
      </Grid>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        autoFocus={false}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ mt: 1 }}
      >
        <MenuItem onClick={handleBillingClick}>
          <ListItemText primary="Billing" />
        </MenuItem>
        <MenuItem onClick={handleSettingsClick}>
          <ListItemText primary="Settings" />
        </MenuItem>
        <MenuItem onClick={handleLogoutClick}>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
