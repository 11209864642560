import { IconButton } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { TimesIcon } from 'components/icons';
import { ProjectsItemData } from 'types/Projects';
import colors from 'theme/colors';
import { MembershipItemData } from 'types/Membership';
import ActionDialog from './ActionDialog';

interface Props {
  membershipData: MembershipItemData;
  project: ProjectsItemData;
}

const RemoveButton = (props: Props): JSX.Element | null => {
  const { project, membershipData } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <TimesIcon
          fontSize="small"
          sx={(theme) => ({
            color: theme.palette.mode === 'dark' ? colors.white : colors.black,
          })}
        />
      </IconButton>
      <ActionDialog
        type="removeUser"
        membershipData={membershipData}
        project={project}
        anchorEl={anchorEl}
        onClose={handleClose}
      />
    </>
  );
};

export default RemoveButton;
