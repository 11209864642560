import { GeneralActions, GeneralActionsEnum, StoreGeneralState } from '../types/generalTypes';
import { GlobalActions, GlobalActionsEnum } from '../types/globalTypes';

const initialState: StoreGeneralState = {
  snackbarNotification: null,
};

const generalReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: GeneralActions | GlobalActions
): StoreGeneralState => {
  switch (action.type) {
    case GeneralActionsEnum.SET_SNACKBAR_NOTIFICATION:
      return { ...state, snackbarNotification: action.payload };
    case GlobalActionsEnum.RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default generalReducer;
