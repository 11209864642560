import { Button, Card, CardActions, CardHeader, Grid, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { BellIcon } from 'components/icons';
import { appLinks } from 'routes/routes';

const AllowAlertsCard = (): JSX.Element | null => {
  return (
    <Grid container>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Typography variant="subtitle1" color="text.primary">
          Browser notifications
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 1 }}>
          <CardHeader
            avatar={<BellIcon sx={{ fill: 'none', width: 48, height: 48 }} />}
            title="Allow browser notifications"
            subheader="Never miss out on a new comment"
            titleTypographyProps={{
              sx: { fontSize: '16px' },
            }}
          />
          <CardActions>
            <Button
              size="large"
              color="primary"
              component={NavLink}
              to={appLinks.pushNotifications.link}
            >
              Allow notifications
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AllowAlertsCard;
