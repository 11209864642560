import { FormEvent, MouseEvent, useCallback, useLayoutEffect, useState } from 'react';
import { mutate } from 'swr';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { ProjectsItemData } from 'types/Projects';
import { useApiRequest, useUserData } from 'hooks';
import forms from 'constants/forms';
import { Loader, TextInput } from 'components';
import { CheckCircleIcon } from 'components/icons';
import projectsApi from 'api/projectsApi';
import DeleteProjectDialog from '../DeleteProjectDialog';

interface Props {
  project: ProjectsItemData;
}

const ProjectDetails = (props: Props): JSX.Element => {
  const { project } = props;
  const { userData } = useUserData();

  const { requestFn, isLoading } = useApiRequest((value) =>
    projectsApi.updateItem(project._id, value)
  );

  const [projectName, setProjectName] = useState(project.title || '');
  const [isError, setIsError] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      if (isError) {
        setIsError(false);
      }

      if (!forms.projectName.validationFn(projectName)) {
        await requestFn({
          args: {
            title: projectName,
          },
          successMessage: 'Project name changed',
        });

        await mutate(projectsApi.urls.getItem(project._id));
      } else {
        setIsError(true);
      }
    },
    [isError, projectName, requestFn, project._id]
  );

  useLayoutEffect(() => {
    if (project.title) {
      setProjectName(project.title);
    }

    return () => setProjectName('');
  }, [project.title]);

  return (
    <Grid container direction="column">
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <TextInput
            inputParams={forms.projectName}
            value={projectName}
            onChange={setProjectName}
            isError={isError}
            InputProps={{
              endAdornment:
                projectName === project.title ? undefined : (
                  <IconButton size="small" type="submit">
                    {isLoading ? <Loader inButton /> : <CheckCircleIcon />}
                  </IconButton>
                ),
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom color="text.primary">
          Information
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Typography gutterBottom color="text.primary">
          Project url
        </Typography>
        <Typography color="primary" sx={{ fontWeight: 600 }}>
          {project.domain}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Typography gutterBottom color="text.primary">
          Total comments
        </Typography>
        <Typography sx={{ fontWeight: 600 }} color="text.primary">
          {project.comments?.length || 0}
        </Typography>
      </Grid>
      {userData?._id === project.owner?._id && (
        <Grid item xs={12} sx={{ mt: 3 }}>
          <Button variant="text" onClick={handleClick}>
            Delete
          </Button>
          <DeleteProjectDialog
            anchorEl={anchorEl}
            onClose={handleClose}
            project={project}
            updateDataKey={projectsApi.urls.getItem(project._id)}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectDetails;
