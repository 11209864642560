import { UsersItemData } from './Users';
import { ProjectsItemData } from './Projects';
import { CommentsItemData, CommentsPublicCommenterData, CommentsReplyItemData } from './Comments';

export enum NotificationTypeEnum {
  NEW_COMMENT_BY_EMAIL = 'newCommentByEmail',
  NEW_COMMENT_IN_BROWSER = 'newCommentInBrowser',
  NEW_COMMENT_IN_APP = 'newCommentInApp',
  NEW_MENTION_BY_EMAIL = 'newMentionByEmail',
  NEW_MENTION_IN_BROWSER = 'newMentionInBrowser',
  NEW_MENTION_IN_APP = 'newMentionInApp',
  RESOLVED_COMMENT_BY_COMMENT_EMAIL = 'resolvedCommentByEmail',
  RESOLVED_COMMENT_IN_BROWSER = 'resolvedCommentInBrowser',
  RESOLVED_COMMENT_IN_APP = 'resolvedCommentInApp',
  NEW_MEMBER_BY_EMAIL = 'newMemberByEmail',
  NEW_MEMBER_IN_BROWSER = 'newMemberInBrowser',
  NEW_MEMBER_IN_APP = 'newMemberInApp',
  NEW_REPLY_BY_EMAIL = 'newReplyByEmail',
  NEW_REPLY_IN_BROWSER = 'newReplyInBrowser',
  NEW_REPLY_IN_APP = 'newReplyInApp',
  NEW_INTEGRATION_IN_APP = 'newIntegrationInApp',
}

export interface NotificationsMembershipItemData {
  color: string;
  member: Pick<UsersItemData, '_id' | 'email' | 'username' | 'avatar'>;
}

export interface NotificationsItemData {
  _id: string;
  readAt: string | null;
  integrationName: string | null;
  notificationType: NotificationTypeEnum;
  createdAt: string;
  recipient: Pick<UsersItemData, '_id' | 'email' | 'username' | 'avatar'>;
  project: Pick<ProjectsItemData, '_id' | 'title' | 'domain'>;
  sender?: Pick<UsersItemData, '_id' | 'email' | 'username' | 'avatar'> & { color: string };
  publicCommenter?: CommentsPublicCommenterData;
  membership?: NotificationsMembershipItemData;
  comment?: Pick<
    CommentsItemData,
    'body' | 'pathname' | 'orderNumber' | 'status' | 'deviceType' | '_id'
  >;
  replyComment?: Pick<CommentsReplyItemData, 'body' | 'pathname' | '_id'>;
}

export interface NotificationsListParams {
  projectId?: string;
}

export type NotificationsListData = NotificationsItemData[];

export type NotificationsListResponse = Promise<NotificationsListData>;

export interface NotificationsMarkManyParams {
  ids: string[];
}

export interface NotificationsProjectSettingsParams {
  newCommentByEmail: boolean;
  newCommentInBrowser: boolean;
  newReplyByEmail: boolean;
  newReplyInBrowser: boolean;
  resolvedCommentByEmail: boolean;
  resolvedCommentInBrowser: boolean;
  newMemberByEmail: boolean;
  newMemberInBrowser: boolean;
  onlyMentionsByEmail: boolean;
  onlyMentionsInBrowser: boolean;
}

export interface NotificationsProjectSettingsData extends NotificationsProjectSettingsParams {
  _id: string;
}

export type NotificationsProjectSettingsResponse = Promise<NotificationsProjectSettingsData>;
