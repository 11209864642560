import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Typography, TypographyProps } from '@mui/material';
import logo from 'assets/logo.png';
import { appLinks } from 'routes/routes';
import { LogoIcon } from './icons';

const useStyles = makeStyles({
  logoContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoText: {
    fontFamily: 'Inter',
    color: '#F9FAFC',
    fontSize: 20,
    marginLeft: 11,
    lineHeight: '20px',
  },
  logoAdditionalTextContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    height: 20,
  },
  logoAdditionalText: {
    color: '#8A93A0',
    fontSize: 8,
    marginLeft: 6,
    fontWeight: 700,
  },
  logoImg: {
    width: 30,
  },
});

interface Props {
  newLogo?: boolean;
  size?: 'small' | 'medium';
  typographyProps?: TypographyProps;
  readonly?: boolean;
}

const Logo = (props: Props): JSX.Element => {
  const { newLogo = false, typographyProps, size, readonly } = props;

  const logoSize = useMemo(() => (size === 'small' ? 24 : 32), [size]);

  const classes = useStyles();
  // todo - fix error with link
  if (newLogo) {
    return (
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'nowrap',
          textDecoration: 'none',
          color: theme.palette.primary.main,
        })}
        {...(!readonly && {
          component: NavLink,
          to: appLinks.index.link,
        })}
      >
        <LogoIcon sx={{ width: logoSize, height: 'auto', mr: 1 }} />
        <Typography
          variant="h4"
          color="textPrimary"
          sx={{
            fontSize: logoSize,
            fontWeight: 700,
            userSelect: 'none',
          }}
          {...typographyProps}
        >
          Greenlight
        </Typography>
      </Box>
    );
  }

  return (
    <div className={classes.logoContainer}>
      <img src={logo} alt="logo" className={classes.logoImg} />
      <span className={classes.logoText}>Greenlight</span>
      <div className={classes.logoAdditionalTextContainer}>
        <span className={classes.logoAdditionalText}>BETA</span>
      </div>
    </div>
  );
};

export default Logo;
