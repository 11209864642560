import { useCallback } from 'react';
import { GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { Button } from '@mui/material';
import { useApiRequest, useSearchParam, useSnackbarNotification, useUserData } from 'hooks';
import authApi from 'api/authApi';
import { useHistory } from 'react-router-dom';
import { appLinks } from 'routes/routes';
import { setApiAuthorizationHeader } from 'api/apiClient';
import { setToken } from 'helpers/cookies';
import { GoogleIcon } from 'components/icons';

const { REACT_APP_GOOGLE_CLIENT_ID: CLIENT_ID } = process.env;

interface Params {
  redirectTo: string;
}

const GoogleSignIn = (): JSX.Element => {
  const { setSnackbarNotification } = useSnackbarNotification();
  const { setUserData } = useUserData();
  const history = useHistory();
  const { redirectTo } = useSearchParam<Params>();
  const { requestFn } = useApiRequest(authApi.signInWithGoogle);

  const onSuccess = useCallback(
    async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      if ('accessToken' in response) {
        const signInData = {
          token: response.accessToken,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };

        const res = await requestFn({
          args: signInData,
        });

        setApiAuthorizationHeader(res.token);
        setToken(res.token);
        setUserData(res);

        history.push(redirectTo || appLinks.projects.link);
      }
    },
    [history, redirectTo, requestFn, setUserData]
  );

  const onFailure = useCallback(() => {
    setSnackbarNotification({
      severity: 'error',
      message: 'Something went wrong with Google sign in.',
    });
  }, [setSnackbarNotification]);

  return (
    <GoogleLogin
      buttonText="Continue with google"
      clientId={CLIENT_ID || ''}
      onSuccess={onSuccess}
      onFailure={onFailure}
      render={(renderProps) => (
        <Button
          size="large"
          color="primary"
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          startIcon={<GoogleIcon />}
        >
          Continue with google
        </Button>
      )}
    />
  );
};

export default GoogleSignIn;

// const googleSignInResponseRaw = {
//   token_type: 'Bearer',
//   access_token:
//     'ya29.a0ARrdaM8nYFCiRzcQZrkUKshrHhpvPh_qMhLDML2mRYhmX_aS_VWuXaew6ogIxcb7r4fHl-eEqZFo6xUwAYqd5IeXpeScCzn1UoXFwJtNGgUdv_ro_pI3DpdU5MxT9uWmZxv9qEp8Jx1Yb85yVdljo2sAih9lzNg',
//   scope:
//     'email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid',
//   login_hint:
//     'AJDLj6LiUwluklAzwwdnydekdo_2faslKBVf9dv9e4DNvR7xh43cQT8gGtjLK5hWCEBR5ZIwznClCxRhNcoq6OpA0VKYmOheNEXY-kbkJ1tUIhfm78nhwSQ',
//   expires_in: 3599,
//   id_token:
//     'eyJhbGciOiJSUzI1NiIsImtpZCI6IjMzZmY1YWYxMmQ3NjY2YzU4Zjk5NTZlNjVlNDZjOWMwMmVmOGU3NDIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiNzA4MzI2MjI0Mzk1LTQzanBjcGsxaTNkNG5mc2JtcHI0OG5lc205N2k0ZGxtLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiNzA4MzI2MjI0Mzk1LTQzanBjcGsxaTNkNG5mc2JtcHI0OG5lc205N2k0ZGxtLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTA3MTYyOTc2NTI5MzQxMTg1NTMyIiwiZW1haWwiOiJtYXlkaWNoNGF2aUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6IkZXWDNCOHprMnozR1M5VVJoMzRLZEEiLCJuYW1lIjoiWWFyb3NsYXYgTWF5ZGljaCIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS0vQU9oMTRHaG1VM2kxNHB4ZV9HOGZ0b3l4Wmw3QVBIbXhsTGc0VGl3ZXROWDVvOVk9czk2LWMiLCJnaXZlbl9uYW1lIjoiWWFyb3NsYXYiLCJmYW1pbHlfbmFtZSI6Ik1heWRpY2giLCJsb2NhbGUiOiJ1ayIsImlhdCI6MTY0MTk5MDY1OSwiZXhwIjoxNjQxOTk0MjU5LCJqdGkiOiJlODM1YTFlY2I1MGY4YWY4NzA4MzhjMDU1ZjJjODI2NjVhZDY1YjM1In0.UepY68jrvnaScrlVWO7n4iYijxdvLQeFDzUdq8xtMG4Jt9CryObXhvS6wNdn0cid3rhL8bL83vuv6DHiXT5MM185xsVAINnPDPwqy7eWmqDxadMaOuGyJJdNJzDJmVeLh3pBZk5eNwezxnfkz7RESM-NmKBJpByyARJEu0Uarc2NOM2shUUaLIqsxoAq1l1Hoqy-Agzxg6oagsdKZMdIkgZjlnLlPP5XF83p5NEKVEeRstn1kr1BjmHF3D4w5IGX_yJhDN8fZrctl8rS9_7zm6U662UgYBwOspz8pwpic-s2n0asvA3iMyf6rCl8Pvdnh8uduSLKivbTlauN5DVrBA',
//   session_state: { extraQueryParams: { authuser: '0' } },
// };
