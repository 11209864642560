const stylesArrayToString = (array: Array<number>): string => array.map((v) => `${v}px`).join(' ');

const easeInOutQuint = (x: number): number =>
  x < 0.5 ? 16 * x * x * x * x * x : 1 - (-2 * x + 2) ** 5 / 2;

const getUserInitials = (name: string): string =>
  name
    .split(' ')
    .map((w) => w.charAt(0))
    .join('')
    .toUpperCase();

export { stylesArrayToString, easeInOutQuint, getUserInitials };
