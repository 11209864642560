import { MouseEvent, useState } from 'react';
import useSWR from 'swr';
import { Badge, badgeClasses, IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import { Loader } from 'components';
import { BellIcon } from 'components/icons';
import notificationsApi from 'api/notificationsApi';
import { ProjectSettingsTabsEnum } from 'types/common';
import NotificationsItem from './NotificationsItem';

interface Props {
  projectId?: string;
  showProjectTitle?: boolean;
  onOpenProjectSettings: (params: {
    activeTab: ProjectSettingsTabsEnum;
    anchorElement: HTMLElement;
  }) => void;
}

const Notifications = (props: Props): JSX.Element | null => {
  const { projectId, showProjectTitle, onOpenProjectSettings } = props;

  const { data, error, mutate } = useSWR([notificationsApi.urls.getList, projectId], () =>
    notificationsApi.getList({ projectId })
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenProjectSettings = (activeTab: ProjectSettingsTabsEnum) => {
    if (anchorEl) {
      onOpenProjectSettings({ activeTab, anchorElement: anchorEl });

      handleClose();
    }
  };

  return (
    <>
      <IconButton size="small" onClick={handleClick} disabled={data?.length === 0}>
        <Badge
          variant="dot"
          badgeContent={data?.filter((n) => n.readAt === null).length}
          invisible={data?.length === 0}
          color="primary"
          sx={{
            [`& .${badgeClasses.dot}`]: {
              transform: 'unset',
              [`&.${badgeClasses.invisible}`]: {
                transform: 'scale(0) translate(50%, -50%)',
              },
            },
          }}
        >
          <BellIcon sx={{ fill: 'none' }} />
        </Badge>
      </IconButton>

      <Menu
        id="notifications-list"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: projectId ? 'right' : 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: projectId ? 'right' : 'center',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ mt: 1 }}
        PaperProps={{
          sx: {
            width: '400px',
          },
        }}
      >
        {!!error && (
          <MenuItem>
            <ListItemText
              primary="Failed to fetch notifications data"
              primaryTypographyProps={{ color: 'error' }}
            />
          </MenuItem>
        )}
        {!data && (
          <MenuItem sx={{ display: 'flex', justifyContent: 'center' }}>
            <Loader height="200px" />
          </MenuItem>
        )}
        {data &&
          data.map((item) => (
            <NotificationsItem
              mutate={mutate}
              data={item}
              key={item._id}
              showProjectTitle={showProjectTitle}
              projectId={projectId}
              onClose={handleClose}
              onOpenProjectSettings={handleOpenProjectSettings}
            />
          ))}
      </Menu>
    </>
  );
};

export default Notifications;
