import {
  UsersItemData,
  UsersItemResponse,
  UsersCreateParams,
  UsersRestorePasswordParams,
  UsersRestorePasswordResponse,
  UsersUpdateParams,
  UsersSetNewPasswordParams,
  UsersSetNewPasswordResponse,
} from 'types/Users';
import ENDPOINTS from './endpoints';
import { apiClient } from './apiClient';

const endpoint = ENDPOINTS.USERS;

const urls = {
  createItem: endpoint,
  getCurrent: `${endpoint}/me`,
  restorePassword: `${endpoint}/restore-password`,
  setNewPassword: `${endpoint}/set-new-password`,
  updateItem: `${endpoint}/update-profile`,
};

const createItem = (data: UsersCreateParams): UsersItemResponse =>
  apiClient.post<UsersItemData>(urls.createItem, data).then((res) => res.data);

const updateItem = ({
  data,
  onUploadProgress,
}: {
  data: UsersUpdateParams;
  onUploadProgress?: (progressEvent: { loaded: number; total: number }) => void;
}): UsersItemResponse => {
  const formData = new FormData();
  const keys = Object.keys(data) as (keyof UsersUpdateParams)[];

  keys.forEach((k) => {
    if (data[k]) {
      if (k === 'avatar' && data.avatar) {
        formData.append(k, data.avatar, data.avatar.name);
      } else {
        formData.append(k, data[k] as string);
      }
    }
  });

  return apiClient
    .post<UsersItemData>(urls.updateItem, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
    })
    .then((res) => res.data);
};

const restorePassword = (data: UsersRestorePasswordParams): UsersRestorePasswordResponse =>
  apiClient.post(urls.restorePassword, data).then((res) => res.data);

const setNewPassword = (data: UsersSetNewPasswordParams): UsersSetNewPasswordResponse =>
  apiClient.post(urls.setNewPassword, data).then((res) => res.data);

const getCurrent = (): UsersItemResponse =>
  apiClient.get<UsersItemData>(urls.getCurrent).then((res) => res.data);

export default { createItem, restorePassword, getCurrent, urls, updateItem, setNewPassword };
