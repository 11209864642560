import dayjs from 'dayjs';

import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '1s',
    ss: '%ss',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: 'a day',
    dd: '%dd',
    M: 'a month',
    MM: '%dM',
    y: 'a year',
    yy: '%dY',
  },
});

const getFormattedDate = (date: string): string => dayjs(date).format('LL');

const getFormattedDateFromNow = (date: string): string => {
  const d = dayjs(date);
  const now = dayjs();

  if (now.diff(d, 'days') > 3) {
    return d.format('LL');
  }

  return d.fromNow();
};

const formatDateLLFromUnix = (date: number): string => dayjs.unix(date).format('MMMM D, YYYY');

export { formatDateLLFromUnix, getFormattedDateFromNow, getFormattedDate };
