import { ProjectsItemData, ProjectsListData } from 'types/Projects';
import { MentionSuggestionOption } from 'types/common';
import {
  StoreProjectAccessRights,
  ProjectsActions,
  ProjectsActionsEnum,
} from '../types/projectsTypes';

const setProjects = (payload: ProjectsListData | undefined): ProjectsActions => ({
  type: ProjectsActionsEnum.SET_PROJECTS,
  payload,
});

const setMentionSuggestionOptions = (payload: MentionSuggestionOption[]): ProjectsActions => ({
  type: ProjectsActionsEnum.SET_MENTION_SUGGESTION_OPTIONS,
  payload,
});

const setActiveProject = (payload: ProjectsItemData | undefined): ProjectsActions => ({
  type: ProjectsActionsEnum.SET_ACTIVE_PROJECT,
  payload,
});

const setProjectAccessRights = (payload: StoreProjectAccessRights): ProjectsActions => ({
  type: ProjectsActionsEnum.SET_PROJECT_ACCESS_RIGHTS,
  payload,
});

export default {
  setProjects,
  setMentionSuggestionOptions,
  setActiveProject,
  setProjectAccessRights,
};
