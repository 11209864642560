import Cookies from 'js-cookie';

const TOKEN = 'greenlight_token';

const getToken = (): string | undefined => Cookies.get(TOKEN);

const setToken = (token: string): string | undefined =>
  Cookies.set(TOKEN, token, {
    expires: 180,
    secure: process.env.NODE_ENV === 'production',
  });

const removeToken = (): void => {
  Cookies.remove(TOKEN);
};

export { getToken, setToken, removeToken };
