import { useCallback, useMemo } from 'react';
import { Button, Card, CardActions, CardHeader, Typography } from '@mui/material';
import { mutate } from 'swr';
import { useHistory } from 'react-router-dom';
import { ProjectsItemData } from 'types/Projects';
import { useApiRequest } from 'hooks';
import integrationsApi from 'api/integrationsApi';
import { Loader } from 'components';
import { SlackIcon } from 'components/icons';
import projectsApi from 'api/projectsApi';
import { appLinks } from 'routes/routes';

interface Props {
  project: ProjectsItemData;
}

const Slack = (props: Props): JSX.Element | null => {
  const { project } = props;
  const history = useHistory();

  const { requestFn, isLoading } = useApiRequest(integrationsApi.disconnectSlack);

  const handleConnect = useCallback(async () => {
    history.push(appLinks.connectSlack.as(project._id));
  }, [history, project._id]);

  const handleDisconnect = useCallback(async () => {
    await requestFn({
      args: { projectId: project._id },
    });

    await mutate(projectsApi.urls.getItem(project._id));
  }, [project._id, requestFn]);

  const subheader = useMemo(() => {
    if (project.slackChannels && project.slackChannels.length > 0) {
      const channelNames = project.slackChannels.map((s) => s.incoming_webhook.channel).join(', ');

      return (
        <Typography variant="body2" color="text.secondary">
          Connected to{' '}
          <Typography variant="inherit" component="span" color="primary">
            {channelNames}
          </Typography>
        </Typography>
      );
    }

    return (
      <Typography variant="body2" color="text.secondary">
        Connect your Slack workspace and get notified of new activity right in your channel.
      </Typography>
    );
  }, [project.slackChannels]);

  return (
    <Card sx={{ p: 1 }}>
      <CardHeader
        avatar={<SlackIcon sx={{ width: 40, height: 40 }} />}
        title="Slack"
        subheader={subheader}
        titleTypographyProps={{
          sx: { fontSize: '16px' },
        }}
      />
      <CardActions>
        {project.slackChannels && project.slackChannels.length > 0 ? (
          <Button
            size="large"
            variant="outlined"
            color="secondary"
            onClick={handleDisconnect}
            disabled={isLoading}
            endIcon={isLoading ? <Loader inButton /> : undefined}
          >
            Disconnect
          </Button>
        ) : (
          <Button size="large" color="primary" onClick={handleConnect}>
            Connect
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default Slack;
