import { NavLink } from 'react-router-dom';
import { Typography, Button, Box, Container } from '@mui/material';
import { Logo } from 'components';
import { appLinks } from 'routes/routes';

interface Props {
  error?: string;
  code?: number;
}

const NotFound = (props: Props): JSX.Element => {
  const { error, code } = props;

  return (
    <Container maxWidth="lg">
      <Box
        py={5}
        sx={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: 'calc(100vh - 80px - 64px)',
        }}
      >
        <Box pb={8}>
          <Logo newLogo />
        </Box>
        <Typography variant="h3" gutterBottom>
          {code || 'Oops'}
        </Typography>
        <Typography variant="h4" gutterBottom>
          {code === 401
            ? 'You dont have permission to view this page'
            : error || 'Something went wrong'}
        </Typography>
        <Box pt={5}>
          {code === 401 ? (
            <Button color="primary" component={NavLink} to={appLinks.signIn.link}>
              Sign in
            </Button>
          ) : (
            <Button color="primary" component={NavLink} to={appLinks.index.link}>
              Go to main
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default NotFound;
