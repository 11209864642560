import { ValidationResponse } from 'types/common';
import {
  IntegrationsConnectGithubResponse,
  IntegrationsConnectParams,
  IntegrationsDisconnectParams,
  IntegrationsSetGithubRepoParams,
  IntegrationsSetGithubRepoResponse,
  IntegrationsGetAtlassianJiraProjectsParams,
  IntegrationsGetAtlassianWorkspacesParams,
  IntegrationsSelectAtlassianWorkspaceData,
  IntegrationsSelectAtlassianIssueTypeData,
  IntegrationsSelectAtlassianJiraProjectData,
  IntegrationsAtlassianWorkspaceResponse,
  IntegrationsAtlassianJiraProjectsResponse,
} from 'types/Integrations';
import ENDPOINTS from './endpoints';
import { apiClient } from './apiClient';

const endpoint = ENDPOINTS.INTEGRATIONS;

const urls = {
  connectSlack: `${endpoint}/slack`,
  disconnectSlack: `${endpoint}/slack/disconnect`,
  connectGithub: `${endpoint}/github`,
  setGithubRepo: `${endpoint}/github/set-github-repo`,
  disconnectGithub: `${endpoint}/github/disconnect`,

  connectAtlassian: `${endpoint}/atlassian`,
  disconnectAtlassian: `${endpoint}/atlassian/disconnect`,
  getAtlassianJiraProjects: `${endpoint}/atlassian/jira-projects`,
  getAtlassianWorkspaces: `${endpoint}/atlassian/workspaces`,
  selectAtlassianWorkspace: `${endpoint}/atlassian/workspaces`,
  selectAtlassianIssueType: `${endpoint}/atlassian/issue-type`,
  selectAtlassianJiraProject: `${endpoint}/atlassian/jira-project`,
};

const connectSlack = (data: IntegrationsConnectParams): ValidationResponse =>
  apiClient.post(urls.connectSlack, data).then((res) => res.data);

const disconnectSlack = (data: IntegrationsDisconnectParams): ValidationResponse =>
  apiClient.patch(urls.disconnectSlack, data).then((res) => res.data);

const connectGithub = (data: IntegrationsConnectParams): IntegrationsConnectGithubResponse =>
  apiClient.post(urls.connectGithub, data).then((res) => res.data);

const setGithubRepo = (data: IntegrationsSetGithubRepoParams): IntegrationsSetGithubRepoResponse =>
  apiClient.post(urls.setGithubRepo, data).then((res) => res.data);

const disconnectGithub = (data: IntegrationsDisconnectParams): ValidationResponse =>
  apiClient.patch(urls.disconnectGithub, data).then((res) => res.data);

const connectAtlassian = (data: IntegrationsConnectParams): ValidationResponse =>
  apiClient.post(urls.connectAtlassian, data).then((res) => res.data);

const disconnectAtlassian = (data: IntegrationsDisconnectParams): ValidationResponse =>
  apiClient.patch(urls.disconnectAtlassian, data).then((res) => res.data);

const getAtlassianJiraProjects = (
  params: IntegrationsGetAtlassianJiraProjectsParams
): IntegrationsAtlassianJiraProjectsResponse =>
  apiClient.get(urls.getAtlassianJiraProjects, { params }).then((res) => res.data);

const getAtlassianWorkspaces = (
  params: IntegrationsGetAtlassianWorkspacesParams
): IntegrationsAtlassianWorkspaceResponse =>
  apiClient.get(urls.getAtlassianWorkspaces, { params }).then((res) => res.data);

const selectAtlassianWorkspace = (
  data: IntegrationsSelectAtlassianWorkspaceData
): Promise<unknown> => apiClient.patch(urls.selectAtlassianWorkspace, data).then((res) => res.data);

const selectAtlassianIssueType = (
  data: IntegrationsSelectAtlassianIssueTypeData
): Promise<unknown> => apiClient.patch(urls.selectAtlassianIssueType, data).then((res) => res.data);

const selectAtlassianJiraProject = (
  data: IntegrationsSelectAtlassianJiraProjectData
): Promise<unknown> =>
  apiClient.patch(urls.selectAtlassianJiraProject, data).then((res) => res.data);

export default {
  connectSlack,
  urls,
  disconnectGithub,
  disconnectAtlassian,
  disconnectSlack,
  connectGithub,
  setGithubRepo,
  connectAtlassian,
  getAtlassianJiraProjects,
  getAtlassianWorkspaces,
  selectAtlassianWorkspace,
  selectAtlassianIssueType,
  selectAtlassianJiraProject,
};
