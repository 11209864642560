import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import useSWR from 'swr';
import { Grid, Typography } from '@mui/material';
import { ProjectsItemData } from 'types/Projects';
import notificationsApi from 'api/notificationsApi';
import { useApiRequest, useUserData } from 'hooks';
import { NotificationsProjectSettingsParams } from 'types/Notifications';
import { NotificationsAlertCheckboxOption } from 'types/common';
import { Loader } from 'components';
import CheckboxGroup from './CheckboxGroup';
import AllowAlertsCard from './AllowAlertsCard';

interface Props {
  project: ProjectsItemData;
}

type CheckAreaType = 'email' | 'browser';

const EMAIL_SETTINGS_OPTIONS: NotificationsAlertCheckboxOption[] = [
  { label: 'All new comments and replies', value: 'newCommentByEmail' },
  { label: 'Only mentions of me', value: 'onlyMentionsByEmail' },
  { label: 'Resolved comments', value: 'resolvedCommentByEmail' },
  { label: 'New members', value: 'newMemberByEmail' },
];

const BROWSER_SETTINGS_OPTIONS: NotificationsAlertCheckboxOption[] = [
  { label: 'All new comments and replies', value: 'newCommentInBrowser' },
  { label: 'Only mentions of me', value: 'onlyMentionsInBrowser' },
  { label: 'Resolved comments', value: 'resolvedCommentInBrowser' },
  { label: 'New members', value: 'newMemberInBrowser' },
];

const AlertsTab = (props: Props): JSX.Element | null => {
  const { project } = props;
  const { userData } = useUserData();

  const { data, error, mutate } = useSWR(
    notificationsApi.urls.getProjectSettings(project._id),
    () => notificationsApi.getProjectSettings(project._id)
  );

  const { isLoading, requestFn } = useApiRequest((value) =>
    notificationsApi.updateProjectSettings(project._id, value)
  );
  const [checkAreaType, setCheckAreaType] = useState<CheckAreaType>('email');

  const isBrowserNotificationsAllowed = useMemo(
    () => !!userData?.pushToken?.enabled,
    [userData?.pushToken?.enabled]
  );

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>, areaType?: CheckAreaType) => {
      if (data) {
        if (areaType) {
          setCheckAreaType(areaType);
        }

        const { checked, name } = event.target;
        let submitData: NotificationsProjectSettingsParams = { ...data };

        switch (name as keyof NotificationsProjectSettingsParams) {
          case 'newCommentByEmail':
            submitData = {
              ...submitData,
              newCommentByEmail: checked,
              newReplyByEmail: checked,
              onlyMentionsByEmail: false,
            };

            break;
          case 'newCommentInBrowser':
            submitData = {
              ...submitData,
              newCommentInBrowser: checked,
              newReplyInBrowser: checked,
              onlyMentionsInBrowser: false,
            };

            break;

          default:
            submitData = { ...submitData, [name]: event.target.checked };
            break;
        }

        const resp = await requestFn({
          args: submitData,
          successMessage: 'Changes saved',
        });
        await mutate(resp);
      }
    },
    [data, mutate, requestFn]
  );

  if (error) {
    return (
      <Grid container>
        <Grid item xs={12} sx={{ height: '70vh' }}>
          <Typography variant="subtitle1" color="text.error">
            Failed to fetch project data
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      {!data ? (
        <Grid item xs={12}>
          <Loader height="60vh" />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <CheckboxGroup
              title="Email notifications"
              onlyMentionsKey="onlyMentionsByEmail"
              collapseOnlyOptionIn={!data?.newCommentByEmail}
              isLoading={isLoading && checkAreaType === 'email'}
              data={data}
              options={EMAIL_SETTINGS_OPTIONS}
              onChange={(e) => handleChange(e, checkAreaType === 'email' ? undefined : 'email')}
            />
          </Grid>
          <Grid item xs={12}>
            {isBrowserNotificationsAllowed ? (
              <CheckboxGroup
                title="Browser notifications"
                onlyMentionsKey="onlyMentionsInBrowser"
                collapseOnlyOptionIn={!data?.newCommentInBrowser}
                isLoading={isLoading && checkAreaType === 'browser'}
                data={data}
                options={BROWSER_SETTINGS_OPTIONS}
                onChange={(e) =>
                  handleChange(e, checkAreaType === 'browser' ? undefined : 'browser')
                }
              />
            ) : (
              <AllowAlertsCard />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default AlertsTab;
