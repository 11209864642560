import { ValidationData, ValidationResponse } from 'types/common';
import {
  NotificationsListData,
  NotificationsListParams,
  NotificationsListResponse,
  NotificationsMarkManyParams,
  NotificationsProjectSettingsData,
  NotificationsProjectSettingsParams,
  NotificationsProjectSettingsResponse,
} from 'types/Notifications';
import ENDPOINTS from './endpoints';
import { apiClient } from './apiClient';

const endpoint = ENDPOINTS.NOTIFICATIONS;

const urls = {
  getList: endpoint,
  getProjectSettings: (projectId: string): string => `${endpoint}/settings/${projectId}`,
  updateProjectSettings: (projectId: string): string => `${endpoint}/settings/${projectId}`,
  markAsRead: `${endpoint}/mark-read`,
  markAsUnread: `${endpoint}/mark-unread`,
};

const getList = (params: NotificationsListParams): NotificationsListResponse =>
  apiClient.get<NotificationsListData>(urls.getList, { params }).then((res) => res.data);

const markAsRead = (data: NotificationsMarkManyParams): ValidationResponse =>
  apiClient.patch<ValidationData>(urls.markAsRead, data).then((res) => res.data);

const markAsUnread = (data: NotificationsMarkManyParams): ValidationResponse =>
  apiClient.patch<ValidationData>(urls.markAsUnread, data).then((res) => res.data);

const getProjectSettings = (projectId: string): NotificationsProjectSettingsResponse =>
  apiClient
    .get<NotificationsProjectSettingsData>(urls.getProjectSettings(projectId))
    .then((res) => res.data);

const updateProjectSettings = (
  projectId: string,
  data: NotificationsProjectSettingsParams
): NotificationsProjectSettingsResponse =>
  apiClient
    .patch<NotificationsProjectSettingsData>(urls.updateProjectSettings(projectId), data)
    .then((res) => res.data);

export default {
  getList,
  urls,
  markAsRead,
  markAsUnread,
  getProjectSettings,
  updateProjectSettings,
};
