import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5 12L9.34483 17L19 7" stroke="currentColor" strokeWidth="1.5" />
  </SvgIcon>
);

export default CheckIcon;
