import { useEffect, useState } from 'react';
import { breakpoints } from 'constants/common';

const is = (v: number) => {
  return {
    between: (v1: number, v2: number) => v1 <= v && v < v2,
  };
};

const useCurrentBreakpoint = (): string => {
  const [breakpoint, setBreakPoint] = useState('');

  useEffect(() => {
    const handleResize = () => {
      const breakpointKeys = Object.keys(breakpoints);
      const breakpointValues = Object.values(breakpoints);

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < breakpointValues.length; i++) {
        if (
          is(window.innerWidth).between(breakpointValues[i], breakpointValues[i + 1] || Infinity)
        ) {
          setBreakPoint(breakpointKeys[i]);
          break;
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return breakpoint;
};

export default useCurrentBreakpoint;
