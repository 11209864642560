import { SnackbarNotificationData } from 'types/common';

export interface StoreGeneralState {
  snackbarNotification: SnackbarNotificationData | null;
}

export enum GeneralActionsEnum {
  SET_SNACKBAR_NOTIFICATION = 'SET_SNACKBAR_NOTIFICATION',
}

interface SetSnackbarNotification {
  type: GeneralActionsEnum.SET_SNACKBAR_NOTIFICATION;
  payload: SnackbarNotificationData | null;
}

export type GeneralActions = SetSnackbarNotification;
