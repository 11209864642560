import { SvgIcon, SvgIconProps } from '@mui/material';

const MouseIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="7" y="5" width="10" height="14" rx="5" stroke="currentColor" strokeWidth="1.5" />
    <path d="M12 5V10" stroke="currentColor" strokeWidth="1.5" />
    <path d="M7 10H17" stroke="currentColor" strokeWidth="1.5" />
  </SvgIcon>
);

export default MouseIcon;
