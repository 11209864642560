import { ReactNode } from 'react';
import { SWRConfig } from 'swr';

interface Props {
  children: ReactNode;
}

const SwrConfigWrapper = (props: Props): JSX.Element => {
  const { children } = props;

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        errorRetryCount: 1,
      }}
    >
      {children}
    </SWRConfig>
  );
};

export default SwrConfigWrapper;
