import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Box } from '@mui/material';

interface Props {
  children: string;
  resizable?: boolean;
  variant?: 'center' | 'right';
  offsetPx?: number;
}

const EllipsisText = (props: Props): JSX.Element => {
  const { children, resizable, variant = 'center', offsetPx = 0 } = props;

  const placeholder = useMemo(() => children.substring(0, 25), [children]);

  const $root = useRef<HTMLDivElement>(null);
  const $child = useRef<HTMLSpanElement>(null);

  const prepEllipse = useCallback(
    (currentTextLength: number) => {
      if ($root.current && $child.current) {
        const blockWidth = $root.current.offsetWidth;
        const spanWidth = $child.current.offsetWidth;
        const divider = `...`;

        const charSize = spanWidth / currentTextLength;
        const originalTextLength = children.length * charSize;

        if (originalTextLength + offsetPx < blockWidth) {
          $child.current.textContent = children;
        } else if (variant === 'center') {
          const charsCanFit = Math.floor(blockWidth / charSize) - 4;
          const firstPart = children.substring(0, Math.floor(charsCanFit / 2));
          const secondPart = children.substring(Math.floor(children.length - charsCanFit / 2));

          $child.current.textContent = firstPart + divider + secondPart.substring(4);
        } else {
          const charsCanFit = Math.floor(blockWidth / charSize) - 5;
          const firstPart = children.substring(0, charsCanFit);

          $child.current.textContent = firstPart + divider;
        }
      }
    },
    [children, offsetPx, variant]
  );

  useEffect(() => {
    if (resizable) {
      window.addEventListener('resize', () => {
        if ($child.current?.textContent) {
          prepEllipse($child.current.textContent.length);
        }
      });
    }

    prepEllipse(placeholder.length);
  }, [placeholder.length, prepEllipse, resizable]);

  return (
    <Box
      ref={$root}
      sx={{
        wordBreak: 'keep-all',
        overflowWrap: 'normal',
        fontWeight: 'inherit',
        fontSize: 'inherit',
      }}
    >
      <span ref={$child}>{placeholder}</span>
    </Box>
  );
};

export default EllipsisText;
