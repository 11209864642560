const getTextWithoutSpaces = (string: string): string => string.replace(/\s/g, '');

const isEmptyString = (string: string): boolean => getTextWithoutSpaces(string).length === 0;

const isInvalidName = (name: string): boolean => getTextWithoutSpaces(name).length < 2;

const isInvalidEmail = (email: string): boolean => {
  const isMatchRegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  if (isMatchRegExp) {
    const localPart = email.split('@')[0];
    const domainPart = email.split('@')[1];

    if (localPart.length <= 64) {
      return false;
    }

    return domainPart.length > 255;
  }

  return true;
};

const isInvalidPassword = (password: string): boolean =>
  !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)[a-zA-Z\d!@#$%&*\S]{8,}$/.test(password);

const isValidCode = (code: string): boolean => /^[0-9]{6}$/.test(code);

export {
  getTextWithoutSpaces,
  isEmptyString,
  isInvalidPassword,
  isInvalidEmail,
  isInvalidName,
  isValidCode,
};
