import { SvgIcon, SvgIconProps } from '@mui/material';

const VectorPlaceholderIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      width="368"
      height="328"
      viewBox="0 0 368 328"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0614129 328V0H368V328H0.0614129ZM3.80772 326H309.18C215.725 316.105 148.552 282.64 101.163 239.212L3.80772 326ZM102.664 237.874C153.699 284.579 227.882 319.729 333.573 326H366V3.12257L102.664 237.874ZM364.254 2L101.186 236.512C90.1573 226.281 80.2248 215.509 71.302 204.375C25.854 147.663 6.54731 81.4965 2.06141 29.4899V2H364.254ZM2.06141 47.0415C9.07452 96.2339 28.936 154.707 69.7413 205.625C78.6898 216.792 88.6434 227.592 99.6865 237.849L2.06141 324.877V47.0415Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default VectorPlaceholderIcon;
