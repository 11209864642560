import { SvgIcon, SvgIconProps } from '@mui/material';

const DesktopIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="4" y="5" width="16" height="12" rx="2" stroke="currentColor" strokeWidth="1.5" />
    <rect x="10" y="17" width="4" height="3" stroke="currentColor" strokeWidth="1.5" />
    <path d="M8 20H16" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </SvgIcon>
);

export default DesktopIcon;
