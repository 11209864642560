import { SvgIcon, SvgIconProps } from '@mui/material';

const MobileIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="8" y="5" width="8" height="14" rx="1" stroke="currentColor" strokeWidth="1.5" />
    <circle cx="12" cy="16" r="1" fill="currentColor" />
  </SvgIcon>
);

export default MobileIcon;
