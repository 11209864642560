import { useCallback } from 'react';
import { UsersItemData } from 'types/Users';
import useStore from './useStore';
import authActions from '../store/actions/authActions';

interface UseUserDataType {
  userData: UsersItemData | null;
  setUserData: (data: UsersItemData | null) => void;
}

const useUserData = (): UseUserDataType => {
  const {
    state: {
      auth: { userData },
    },
    dispatch,
  } = useStore();

  const setUserData = useCallback(
    (data: UsersItemData | null) => dispatch(authActions.setUserData(data)),
    [dispatch]
  );

  return { userData, setUserData };
};

export default useUserData;
