import { SvgIcon, SvgIconProps } from '@mui/material';

const CopyIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="5.5"
      y="5"
      width="9"
      height="9"
      rx="2"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 10H17.5C18.6046 10 19.5 10.8954 19.5 12V17C19.5 18.1046 18.6046 19 17.5 19H12.5C11.3954 19 10.5 18.1046 10.5 17V16"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default CopyIcon;
