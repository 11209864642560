import { ChangeEvent, FC } from 'react';
import { Grid, InputAdornment, TextField, TextFieldProps, Typography } from '@mui/material';
import { getTextWithoutSpaces } from 'helpers/validations';

interface InputParams {
  label: string;
  placeholder: string;
  error: string;
  limit?: number;
  validationFn?: (value: string) => boolean;
}

interface Props extends Omit<TextFieldProps, 'onChange' | 'value'> {
  inputParams: InputParams;
  value: string | undefined;
  onChange: (value: string) => void;
  isError?: boolean;
}

const TextInput: FC<Props> = (props: Props) => {
  const { value = '', onChange, inputParams, isError, helperText, ...rest } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (inputParams.limit) {
      if (e.target.value.length <= inputParams.limit) {
        onChange(e.target.value);
      }
    } else {
      onChange(e.target.value);
    }
  };

  const error = isError && inputParams.validationFn && inputParams.validationFn(value);

  const helperTextWithError = error ? inputParams.error : helperText;

  const endAdornment = rest.InputProps?.endAdornment ? (
    <InputAdornment position="end">
      <Grid container columnSpacing={1} wrap="nowrap" alignItems="center">
        <Grid item>
          <Typography color="textSecondary">
            {value.length}/{inputParams.limit}
          </Typography>
        </Grid>
        <Grid item>{rest.InputProps?.endAdornment}</Grid>
      </Grid>
    </InputAdornment>
  ) : (
    <InputAdornment position="end">
      <Typography color="textSecondary">
        {value.length}/{inputParams.limit}
      </Typography>
    </InputAdornment>
  );

  return (
    <TextField
      id={getTextWithoutSpaces(inputParams.label).toLowerCase()}
      label={inputParams.label}
      placeholder={inputParams.placeholder}
      error={error}
      helperText={helperTextWithError}
      {...rest}
      variant="outlined"
      value={value}
      onChange={handleChange}
      InputProps={{
        notched: false,
        ...rest.InputProps,
        endAdornment:
          inputParams.limit && inputParams.limit - value.length < 10 ? (
            endAdornment
          ) : (
            <InputAdornment position="end">{rest.InputProps?.endAdornment}</InputAdornment>
          ),
      }}
    />
  );
};

export default TextInput;
