import { SvgIcon, SvgIconProps } from '@mui/material';

const WindowsIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 6.21429V11.6429H10.5357V5.32143L4 6.21429ZM4 17.8214L10.5357 18.7143V12.4643H4V17.8214ZM11.25 18.8214L20 20V12.4643H11.25V18.8214ZM11.25 5.21429V11.6429H20V4L11.25 5.21429Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default WindowsIcon;
