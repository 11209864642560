import { lazy, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useUserData } from 'hooks';
import { appLinks } from './routes';
import NotFoundPage from './NotFound';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const SignIn = lazy(() => import('containers/public/SignIn'));
const Registration = lazy(() => import('containers/public/Registration'));
const SignInConfirmCode = lazy(() => import('containers/public/SignInConfirmCode'));
const RestorePassword = lazy(() => import('containers/public/RestorePassword'));
const PushNotifications = lazy(() => import('containers/private/PushNotifications'));

const Projects = lazy(() => import('containers/private/Projects'));
const Settings = lazy(() => import('containers/private/Settings'));
const Billing = lazy(() => import('containers/private/Billing'));
const ChangePassword = lazy(() => import('containers/private/Settings/ChangePassword'));
const ApplyLicenseKey = lazy(() => import('containers/private/Billing/ApplyLicenseKey'));
const Project = lazy(() => import('containers/private/Project'));

const ConnectSlack = lazy(() => import('containers/private/SlackIntegration/ConnectSlack'));
const SlackConnected = lazy(() => import('containers/private/SlackIntegration/SlackConnected'));
const ConnectGithub = lazy(() => import('containers/private/GithubIntegration/ConnectGithub'));
const GithubConnected = lazy(() => import('containers/private/GithubIntegration/GithubConnected'));
const ConnectAtlassian = lazy(
  () => import('containers/private/AtlassianIntegration/ConnectAtlassian')
);
const AtlassianConnected = lazy(
  () => import('containers/private/AtlassianIntegration/AtlassianConnected')
);

// todo - refactor old pages
const Landing = lazy(() => import('../containers/public/Landing/Landing'));

const commonRoutes = [
  {
    exact: true,
    path: [appLinks.projectDetails.link, appLinks.shareToWebProject.link],
    component: Project,
  },
];

const Router = (): JSX.Element => {
  const { userData } = useUserData();

  const isAuthenticated = useMemo(() => !!userData, [userData]);

  const publicRoutes = useMemo(
    () => [
      { exact: true, isAuthenticated, path: '/', component: Landing },
      {
        exact: true,
        isAuthenticated,
        path: [appLinks.signIn.link, '/sign-in-with-google'],
        component: SignIn,
      },
      {
        exact: true,
        isAuthenticated,
        path: [appLinks.register.link, '/invitation'],
        component: Registration,
      },
      {
        exact: true,
        isAuthenticated,
        path: appLinks.signInConfirmCode.link,
        component: SignInConfirmCode,
      },
      {
        exact: true,
        isAuthenticated,
        path: appLinks.forgotPassword.link,
        component: RestorePassword,
      },
    ],
    [isAuthenticated]
  );

  const privateRoutes = useMemo(
    () => [
      {
        exact: true,
        isAuthenticated,
        path: appLinks.connectSlack.link,
        component: ConnectSlack,
      },
      {
        exact: true,
        isAuthenticated,
        path: appLinks.slackConnected.link,
        component: SlackConnected,
      },
      {
        exact: true,
        isAuthenticated,
        path: appLinks.connectGithub.link,
        component: ConnectGithub,
      },
      {
        exact: true,
        isAuthenticated,
        path: appLinks.githubConnected.link,
        component: GithubConnected,
      },
      {
        exact: true,
        isAuthenticated,
        path: appLinks.connectAtlassian.link,
        component: ConnectAtlassian,
      },
      {
        exact: true,
        isAuthenticated,
        path: appLinks.atlassianConnected.link,
        component: AtlassianConnected,
      },
      {
        exact: true,
        isAuthenticated,
        path: appLinks.pushNotifications.link,
        component: PushNotifications,
      },

      {
        exact: true,
        isAuthenticated,
        path: appLinks.projects.link,
        component: Projects,
      },
      {
        exact: true,
        isAuthenticated,

        path: appLinks.settings.link,
        component: Settings,
      },
      {
        exact: true,
        isAuthenticated,
        path: appLinks.billing.link,
        component: Billing,
      },
      {
        exact: true,
        isAuthenticated,
        path: appLinks.changePassword.link,
        component: ChangePassword,
      },
      {
        exact: true,
        isAuthenticated,
        path: appLinks.applyLicenseKey.link,
        component: ApplyLicenseKey,
      },
    ],
    [isAuthenticated]
  );

  return (
    <Switch>
      {commonRoutes.map((r) => (
        <Route key={Array.isArray(r.path) ? r.path[0] : r.path} {...r} />
      ))}
      {publicRoutes.map((r) => (
        <PublicRoute key={Array.isArray(r.path) ? r.path[0] : r.path} {...r} />
      ))}
      {privateRoutes.map((r) => (
        <PrivateRoute key={Array.isArray(r.path) ? r.path[0] : r.path} {...r} />
      ))}
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default Router;
