import { UsersItemData } from 'types/Users';
import {
  AuthActions,
  AuthActionsEnum,
  ResendCodeTimerData,
  StoreAuthGuestUserData,
} from '../types/authTypes';

const setUserData = (payload: UsersItemData | null): AuthActions => ({
  type: AuthActionsEnum.SET_USER_DATA,
  payload,
});

const setResendCodeEmail = (payload: string | undefined): AuthActions => ({
  type: AuthActionsEnum.SET_RESEND_CODE_EMAIL,
  payload,
});

const setResendCodeTimerData = (payload: ResendCodeTimerData | undefined): AuthActions => ({
  type: AuthActionsEnum.SET_RESEND_CODE_TIMER_DATA,
  payload,
});

const setGuestUserData = (payload: StoreAuthGuestUserData | undefined): AuthActions => ({
  type: AuthActionsEnum.SET_GUEST_USER_DATA,
  payload,
});

const setIsGuestUserModalOpen = (payload: boolean): AuthActions => ({
  type: AuthActionsEnum.SET_IS_GUEST_USER_MODAL_OPEN,
  payload,
});

export default {
  setUserData,
  setResendCodeTimerData,
  setResendCodeEmail,
  setGuestUserData,
  setIsGuestUserModalOpen,
};
