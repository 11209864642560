import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { appLinks } from './routes';

interface Props extends RouteProps {
  isAuthenticated: boolean;
}

const PrivateRoute: React.FC<Props> = ({ isAuthenticated, ...rest }: Props) => {
  const { pathname } = useLocation();

  return isAuthenticated ? (
    <Route {...rest} />
  ) : (
    <Redirect to={`${appLinks.signIn.link}?redirectTo=${pathname}`} />
  );
};

export default PrivateRoute;
