import { SyntheticEvent, useLayoutEffect, useState } from 'react';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import useSWR from 'swr';
import { ProjectSettingsTabsEnum } from 'types/common';
import projectsApi from 'api/projectsApi';
import { Loader } from 'components';
import { ProjectsItemData } from 'types/Projects';
import DetailsTab from './DetailsTab';
import AlertsTab from './AlertsTab';
import IntegrationsTab from './IntegrationsTab';
import MembersTab from './MembersTab';

interface Props {
  projectId: string;
  projectData?: ProjectsItemData;
  isOpen: boolean;
  onClose: () => void;
  activeTab?: ProjectSettingsTabsEnum;
}

const TABS = [
  ProjectSettingsTabsEnum.DETAILS,
  ProjectSettingsTabsEnum.MEMBERS,
  ProjectSettingsTabsEnum.ALERTS,
  ProjectSettingsTabsEnum.INTEGRATIONS,
];

const ProjectTabs = (props: Props): JSX.Element | null => {
  const { projectId, activeTab, onClose, isOpen, projectData } = props;

  const { data = projectData, error } = useSWR(
    isOpen && !projectData ? projectsApi.urls.getItem(projectId) : null,
    () => projectsApi.getItem(projectId)
  );

  const [selectedTab, setSelectedTab] = useState<ProjectSettingsTabsEnum>(
    ProjectSettingsTabsEnum.DETAILS
  );

  const handleSelectedTabChange = (event: SyntheticEvent, newValue: ProjectSettingsTabsEnum) => {
    setSelectedTab(newValue);
  };

  const handleClose = () => {
    onClose();
  };

  useLayoutEffect(() => {
    if (activeTab) {
      setSelectedTab(activeTab);
    }
  }, [activeTab]);

  return (
    <Grid container>
      {!data && !error && (
        <Grid item xs={12} sx={{ minWidth: '400px' }}>
          <Loader height="70vh" />
        </Grid>
      )}
      {!!error && (
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="text.error">
            Failed to fetch project data
          </Typography>
        </Grid>
      )}
      {!!data && (
        <>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={selectedTab} onChange={handleSelectedTabChange} variant="fullWidth">
                {TABS.map((t) => (
                  <Tab label={t} value={t} key={t} />
                ))}
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {selectedTab === ProjectSettingsTabsEnum.DETAILS && <DetailsTab project={data} />}
            {selectedTab === ProjectSettingsTabsEnum.MEMBERS && (
              <MembersTab project={data} closeSettingsDialog={handleClose} />
            )}
            {selectedTab === ProjectSettingsTabsEnum.ALERTS && <AlertsTab project={data} />}
            {selectedTab === ProjectSettingsTabsEnum.INTEGRATIONS && (
              <IntegrationsTab project={data} />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ProjectTabs;
