import { SvgIcon, SvgIconProps } from '@mui/material';

const CheckCircleIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.75 12C4.75 7.99594 7.99594 4.75 12 4.75C16.0041 4.75 19.25 7.99594 19.25 12C19.25 16.0041 16.0041 19.25 12 19.25C7.99594 19.25 4.75 16.0041 4.75 12ZM12 3.25C7.16751 3.25 3.25 7.16751 3.25 12C3.25 16.8325 7.16751 20.75 12 20.75C16.8325 20.75 20.75 16.8325 20.75 12C20.75 7.16751 16.8325 3.25 12 3.25ZM11.0223 15.2352L16.5396 9.52095L15.4604 8.47905L10.5119 13.6044L8.56612 11.3652L7.43388 12.3491L9.91664 15.2062L10.4536 15.8242L11.0223 15.2352Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default CheckCircleIcon;
