import { SvgIcon, SvgIconProps } from '@mui/material';

const WorldIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 4.18994C6.78906 4.18994 4.1875 6.7915 4.1875 10.0024C4.1875 13.2134 6.78906 15.8149 10 15.8149C13.2109 15.8149 15.8125 13.2134 15.8125 10.0024C15.8125 6.7915 13.2109 4.18994 10 4.18994ZM14.1953 7.93994H12.625C12.4609 7.09619 12.2031 6.34619 11.875 5.73682C12.8828 6.18213 13.7031 6.95557 14.1953 7.93994ZM10 5.31494C10.4219 5.31494 11.125 6.29932 11.4766 7.93994H8.5C8.85156 6.29932 9.55469 5.31494 10 5.31494ZM5.3125 10.0024C5.3125 9.69775 5.33594 9.36963 5.40625 9.06494H7.21094C7.1875 9.39307 7.1875 9.69775 7.1875 10.0024C7.1875 10.3306 7.1875 10.6353 7.21094 10.9399H5.40625C5.33594 10.6587 5.3125 10.3306 5.3125 10.0024ZM5.78125 12.0649H7.35156C7.51562 12.9321 7.77344 13.6821 8.10156 14.2915C7.09375 13.8462 6.27344 13.0493 5.78125 12.0649ZM7.35156 7.93994H5.78125C6.27344 6.95557 7.09375 6.18213 8.10156 5.73682C7.77344 6.34619 7.51562 7.09619 7.35156 7.93994ZM10 14.6899C9.55469 14.6899 8.85156 13.729 8.5 12.0649H11.4766C11.125 13.729 10.4219 14.6899 10 14.6899ZM11.6406 10.9399H8.33594C8.3125 10.6587 8.3125 10.3306 8.3125 10.0024C8.3125 9.67432 8.3125 9.36963 8.33594 9.06494H11.6406C11.6641 9.36963 11.6875 9.67432 11.6875 10.0024C11.6875 10.3306 11.6641 10.6587 11.6406 10.9399ZM11.875 14.2915C12.2031 13.6821 12.4609 12.9321 12.625 12.0649H14.1953C13.7031 13.0493 12.8828 13.8462 11.875 14.2915ZM12.7656 10.9399C12.7891 10.6353 12.8125 10.3306 12.8125 10.0024C12.8125 9.69775 12.7891 9.39307 12.7656 9.06494H14.5938C14.6406 9.36963 14.6875 9.69775 14.6875 10.0024C14.6875 10.3306 14.6406 10.6587 14.5938 10.9399H12.7656Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default WorldIcon;
