import { combineReducers, createStore } from 'redux';
import authReducer from './reducers/authReducer';
import { StoreAuthState } from './types/authTypes';
import generalReducer from './reducers/generalReducer';
import { StoreGeneralState } from './types/generalTypes';
import projectsReducer from './reducers/projectsReducer';
import { StoreProjectsState } from './types/projectsTypes';
import commentsReducer from './reducers/commentsReducer';
import { StoreCommentsState } from './types/commentsTypes';

const rootReducer = combineReducers({
  auth: authReducer,
  general: generalReducer,
  projects: projectsReducer,
  comments: commentsReducer,
});

// export type RootState = ReturnType<typeof rootReducer>;
export interface StoreRootState {
  auth: StoreAuthState;
  general: StoreGeneralState;
  projects: StoreProjectsState;
  comments: StoreCommentsState;
}

const store = createStore(rootReducer);

export default store;
