import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { getTextWithoutSpaces } from 'helpers/validations';
import { EyeClosedIcon, EyeOpenIcon } from '../icons';

interface InputParams {
  label: string;
  placeholder: string;
  error: string;
  validationFn?: (value: string) => boolean;
}

export interface PasswordInputProps extends Omit<TextFieldProps, 'onChange' | 'value'> {
  inputParams: InputParams;
  value: string;
  onChange: (value: string) => void;
  isError?: boolean;
}

const PasswordInput: React.FC<PasswordInputProps> = (props: PasswordInputProps) => {
  const { value, onChange, inputParams, isError, ...rest } = props;

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleClick = () => setIsPasswordVisible((prevState) => !prevState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  const error = isError && inputParams.validationFn && inputParams.validationFn(value);

  const helperText = error && inputParams.error;

  return (
    <TextField
      id={getTextWithoutSpaces(inputParams.label).toLowerCase()}
      type={isPasswordVisible ? 'text' : 'password'}
      label={inputParams.label}
      placeholder={inputParams.placeholder}
      error={error}
      helperText={helperText}
      {...rest}
      variant="outlined"
      value={value}
      onChange={handleChange}
      InputProps={{
        notched: false,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton color="inherit" size="small" onClick={handleClick}>
              {isPasswordVisible ? <EyeOpenIcon /> : <EyeClosedIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
