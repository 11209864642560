import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export type HandleModelChangeFn<T> = <Key extends keyof T>(key: Key, value: T[Key]) => void;

const useModel = <T>(initialModel: T): [T, HandleModelChangeFn<T>, Dispatch<SetStateAction<T>>] => {
  const [model, setModel] = useState<T>(initialModel);

  const handleChange = useCallback(<Key extends keyof T>(key: Key, value: T[Key]) => {
    setModel((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  return [model, handleChange, setModel];
};

export default useModel;
