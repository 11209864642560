import { SvgIcon, SvgIconProps } from '@mui/material';

const LogoDarkWithTextIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="69"
    height="14"
    viewBox="0 0 69 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.4735 6.57635V7.81368H23.8041C23.6216 8.67237 23.2193 9.33836 22.5972 9.81166C21.9819 10.2782 21.1875 10.5115 20.2138 10.5115C19.0915 10.5115 18.182 10.1633 17.4856 9.46683C16.796 8.76365 16.4545 7.87115 16.4613 6.78933C16.4613 5.71427 16.8027 4.81501 17.4856 4.09155C18.1753 3.36808 19.1151 3.00635 20.3051 3.00635C21.2449 3.00635 22.0225 3.23285 22.6378 3.68586C23.2598 4.13888 23.6013 4.71359 23.6621 5.41001H21.867C21.7994 5.10575 21.627 4.85896 21.3497 4.66964C21.0793 4.48032 20.7345 4.38567 20.3153 4.38567C19.6188 4.38567 19.0847 4.60541 18.7128 5.0449C18.3477 5.48439 18.1685 6.05572 18.1753 6.7589C18.1685 7.43504 18.3545 7.99285 18.7331 8.43234C19.1185 8.86507 19.6256 9.08143 20.2544 9.08143C21.2483 9.08143 21.867 8.65885 22.1104 7.81368H20.8426V6.57635H24.4735Z"
      fill="white"
    />
    <path
      d="M25.2649 10.3086V5.42015H26.8166V6.4445C26.8978 6.09291 27.0634 5.82246 27.3136 5.63314C27.5705 5.43706 27.8545 5.33902 28.1655 5.33902C28.3278 5.33902 28.463 5.35254 28.5712 5.37959V6.81976C28.3886 6.79947 28.2331 6.78933 28.1047 6.78933C27.246 6.78933 26.8166 7.33362 26.8166 8.4222V10.3086H25.2649Z"
      fill="white"
    />
    <path
      d="M34.1825 7.61084C34.1825 7.81368 34.169 8.04018 34.142 8.29035H30.5314C30.5449 8.60814 30.6531 8.85493 30.8559 9.03072C31.0588 9.20652 31.3326 9.29442 31.6774 9.29442C32.1507 9.29442 32.455 9.14229 32.5902 8.83802H34.1115C34.0372 9.31808 33.7735 9.71024 33.3205 10.0145C32.8742 10.312 32.3265 10.4608 31.6774 10.4608C30.8458 10.4608 30.1899 10.2309 29.7099 9.77109C29.2298 9.31132 28.9898 8.67575 28.9898 7.86439C28.9898 7.06655 29.2298 6.43774 29.7099 5.97797C30.1899 5.51143 30.8289 5.27817 31.6267 5.27817C32.384 5.27817 32.9993 5.49453 33.4726 5.92726C33.9459 6.35998 34.1825 6.92118 34.1825 7.61084ZM30.5314 7.32686H32.6511C32.6443 7.00231 32.553 6.75214 32.3772 6.57635C32.2014 6.40055 31.9479 6.31265 31.6166 6.31265C31.2853 6.31265 31.0216 6.40731 30.8255 6.59663C30.6362 6.77919 30.5382 7.0226 30.5314 7.32686Z"
      fill="white"
    />
    <path
      d="M40.0459 7.61084C40.0459 7.81368 40.0324 8.04018 40.0053 8.29035H36.3948C36.4083 8.60814 36.5165 8.85493 36.7193 9.03072C36.9221 9.20652 37.196 9.29442 37.5408 9.29442C38.0141 9.29442 38.3184 9.14229 38.4536 8.83802H39.9749C39.9005 9.31808 39.6368 9.71024 39.1838 10.0145C38.7376 10.312 38.1899 10.4608 37.5408 10.4608C36.7092 10.4608 36.0533 10.2309 35.5733 9.77109C35.0932 9.31132 34.8532 8.67575 34.8532 7.86439C34.8532 7.06655 35.0932 6.43774 35.5733 5.97797C36.0533 5.51143 36.6923 5.27817 37.4901 5.27817C38.2474 5.27817 38.8627 5.49453 39.336 5.92726C39.8093 6.35998 40.0459 6.92118 40.0459 7.61084ZM36.3948 7.32686H38.5144C38.5077 7.00231 38.4164 6.75214 38.2406 6.57635C38.0648 6.40055 37.8113 6.31265 37.48 6.31265C37.1487 6.31265 36.885 6.40731 36.6889 6.59663C36.4996 6.77919 36.4015 7.0226 36.3948 7.32686Z"
      fill="white"
    />
    <path
      d="M40.9336 10.3086V5.42015H42.4853V6.1301C42.776 5.5689 43.3034 5.28831 44.0675 5.28831C45.2575 5.28831 45.8525 5.9543 45.8525 7.28629V10.3086H44.2906V7.79339C44.2906 7.37419 44.223 7.06993 44.0877 6.88061C43.9593 6.69129 43.7395 6.59663 43.4285 6.59663C42.7997 6.59663 42.4853 6.98203 42.4853 7.75283V10.3086H40.9336Z"
      fill="white"
    />
    <path d="M47.0644 10.3086V3.20919H48.6161V10.3086H47.0644Z" fill="white" />
    <path
      d="M51.3676 4.71021C51.185 4.89277 50.9551 4.98405 50.6779 4.98405C50.4007 4.98405 50.1708 4.89277 49.9883 4.71021C49.8125 4.52765 49.7246 4.30453 49.7246 4.04084C49.7246 3.77038 49.8125 3.54388 49.9883 3.36132C50.1708 3.17876 50.4007 3.08748 50.6779 3.08748C50.9551 3.08748 51.185 3.18214 51.3676 3.37146C51.5501 3.55402 51.6414 3.77714 51.6414 4.04084C51.6414 4.30453 51.5501 4.52765 51.3676 4.71021ZM49.897 10.3086V5.42015H51.4487V10.3086H49.897Z"
      fill="white"
    />
    <path
      d="M56.2286 5.42015H57.7804V9.97393C57.7804 10.9205 57.466 11.6136 56.8372 12.0531C56.4247 12.3438 55.8432 12.4892 55.0927 12.4892C54.3557 12.4892 53.7675 12.3573 53.328 12.0936C53.0778 11.9381 52.8716 11.7386 52.7094 11.4952C52.5471 11.2518 52.4626 10.9983 52.4558 10.7346H54.038C54.1123 11.147 54.4572 11.3533 55.0724 11.3533C55.8432 11.3533 56.2286 10.9577 56.2286 10.1666V9.51754C56.1069 9.75419 55.9007 9.94689 55.61 10.0956C55.326 10.2376 55.0217 10.3086 54.6972 10.3086C53.9872 10.3086 53.4227 10.0787 53.0035 9.61896C52.5843 9.15243 52.3747 8.55067 52.3747 7.81368C52.3747 7.08345 52.591 6.48169 53.0238 6.00839C53.4565 5.52834 54.0211 5.28831 54.7175 5.28831C55.0555 5.28831 55.3598 5.36606 55.6303 5.52157C55.9075 5.67032 56.1069 5.86979 56.2286 6.11996V5.42015ZM55.1232 9.11186C55.4815 9.11186 55.7655 8.99016 55.9751 8.74675C56.1915 8.50334 56.2996 8.19908 56.2996 7.83396C56.2996 7.46885 56.1915 7.16459 55.9751 6.92118C55.7655 6.67777 55.4815 6.55606 55.1232 6.55606C54.7783 6.55606 54.4977 6.67777 54.2814 6.92118C54.0718 7.15783 53.967 7.46209 53.967 7.83396C53.967 8.20584 54.0718 8.51348 54.2814 8.75689C54.4977 8.99354 54.7783 9.11186 55.1232 9.11186Z"
      fill="white"
    />
    <path
      d="M62.1924 5.28831C63.3824 5.28831 63.9774 5.9543 63.9774 7.28629V10.3086H62.4155V7.79339C62.4155 7.37419 62.3479 7.06993 62.2127 6.88061C62.0842 6.69129 61.8645 6.59663 61.5535 6.59663C60.9247 6.59663 60.6102 6.98203 60.6102 7.75283V10.3086H59.0585V3.20919H60.6102V6.1301C60.901 5.5689 61.4284 5.28831 62.1924 5.28831Z"
      fill="white"
    />
    <path
      d="M67.6958 9.10172C67.9122 9.10172 68.0914 9.09158 68.2333 9.07129V10.3086C67.9358 10.3627 67.591 10.3898 67.1989 10.3898C67.0231 10.3898 66.8675 10.3796 66.7323 10.3593C66.5971 10.339 66.445 10.2951 66.2759 10.2275C66.1069 10.1531 65.9683 10.0551 65.8601 9.93337C65.7519 9.81166 65.6573 9.63587 65.5761 9.40598C65.5017 9.17609 65.4646 8.90564 65.4646 8.59461V6.64734H64.6938V5.42015H65.4646V3.96984H67.0163V5.42015H68.1218V6.64734H67.0163V8.27007C67.0163 8.60138 67.0738 8.8245 67.1887 8.93944C67.3037 9.04763 67.4727 9.10172 67.6958 9.10172Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.44945 1.53174H7.55008V2.90753H7.53197C9.385 3.14612 10.8531 4.61361 11.0927 6.46633V6.45018H12.4685V7.55081H11.0927V7.53542C10.8534 9.38617 9.38813 10.8525 7.53789 11.0935H7.55008V12.4693H6.44945V11.0935H6.46145C4.6117 10.8526 3.14671 9.38695 2.90679 7.53689V7.55081H1.53101V6.45018H2.90679V6.46487C3.14697 4.61284 4.61482 3.14606 6.46736 2.90753H6.44945V1.53174ZM6.99967 3.97416C5.32805 3.97416 3.97294 5.32927 3.97294 7.00089C3.97294 8.67251 5.32805 10.0276 6.99967 10.0276C8.67129 10.0276 10.0264 8.67251 10.0264 7.00089C10.0264 5.32927 8.67129 3.97416 6.99967 3.97416Z"
      fill="url(#paint0_linear_823_21237)"
    />
    <mask id="mask0_823_21237" maskUnits="userSpaceOnUse" x="2" y="2" width="10" height="10">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99999 2.97557C4.7775 2.97557 2.97581 4.77726 2.97581 6.99975C2.97581 9.22224 4.7775 11.0239 6.99999 11.0239C9.22248 11.0239 11.0242 9.22224 11.0242 6.99975C11.0242 4.77726 9.22248 2.97557 6.99999 2.97557ZM6.99997 3.83542C5.25237 3.83542 3.83566 5.25213 3.83566 6.99973C3.83566 8.74733 5.25237 10.164 6.99997 10.164C8.74757 10.164 10.1643 8.74733 10.1643 6.99973C10.1643 5.25213 8.74757 3.83542 6.99997 3.83542Z"
        fill="url(#paint1_linear_823_21237)"
      />
    </mask>
    <g mask="url(#mask0_823_21237)">
      <g filter="url(#filter0_f_823_21237)">
        <ellipse
          cx="7"
          cy="7.00024"
          rx="3.16431"
          ry="3.16431"
          transform="rotate(-180 7 7.00024)"
          stroke="#E7F1FA"
          strokeWidth="0.297575"
        />
      </g>
      <g filter="url(#filter1_f_823_21237)">
        <circle
          cx="6.99984"
          cy="7.00008"
          r="4.12736"
          transform="rotate(-180 6.99984 7.00008)"
          stroke="#084477"
          strokeWidth="0.297575"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_823_21237"
        x="1.30641"
        y="1.30641"
        width="11.3872"
        height="11.3877"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="1.1903" result="effect1_foregroundBlur_823_21237" />
      </filter>
      <filter
        id="filter1_f_823_21237"
        x="1.23576"
        y="1.23624"
        width="11.528"
        height="11.528"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="0.743939" result="effect1_foregroundBlur_823_21237" />
      </filter>
      <linearGradient
        id="paint0_linear_823_21237"
        x1="2.73482"
        y1="12.1138"
        x2="7.61675"
        y2="1.68583"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2746E7" />
        <stop offset="0.614583" stopColor="#A354F1" />
        <stop offset="1" stopColor="#1AB1ED" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_823_21237"
        x1="10.1987"
        y1="3.16474"
        x2="6.53725"
        y2="10.9857"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2746E7" />
        <stop offset="0.614583" stopColor="#A354F1" />
        <stop offset="1" stopColor="#1AB1ED" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default LogoDarkWithTextIcon;
