import { AppBar, Container, Grid, Toolbar, IconButton, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import colors from 'theme/colors';
import { TOOLBAR_HEIGHT } from 'constants/common';
import { ArrowRightIcon } from 'components/icons';
import { appLinks } from 'routes/routes';
import Logo from '../Logo';
import UserMenu from './UserMenu';

interface Props {
  title?: string;
  backUrl?: string;
}

const Header = (props: Props): JSX.Element => {
  const { backUrl, title } = props;

  const history = useHistory();

  const handleClick = () => (backUrl ? history.push(backUrl) : history.go(-1));

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={(theme) => ({
        bgcolor: theme.palette.mode === 'dark' ? theme.palette.grey['900'] : colors.white,
        color: theme.palette.mode === 'dark' ? colors.white : colors.black,
      })}
    >
      <Toolbar
        sx={(theme) => ({
          px: 0,
          height: `${TOOLBAR_HEIGHT}px`,
          borderBottom: `0.5px solid ${
            theme.palette.mode === 'dark' ? colors.darkGrey1 : colors.lightGrey4
          }`,
          [theme.breakpoints.up('sm')]: {
            px: 0,
          },
        })}
      >
        <Container>
          <Grid container columnGap={1} alignItems="center" wrap="nowrap">
            {history.location.pathname !== appLinks.projects.link && (
              <Grid item>
                <IconButton onClick={handleClick}>
                  <ArrowRightIcon sx={{ fill: 'none', transform: 'rotate(180deg)' }} />
                </IconButton>
              </Grid>
            )}
            <Grid item sx={{ mr: 'auto' }}>
              {title ? (
                <Typography fontWeight={600} fontSize={24} sx={{ userSelect: 'none' }}>
                  {title}
                </Typography>
              ) : (
                <Logo size="small" newLogo />
              )}
            </Grid>
            <Grid item>
              <UserMenu />
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
