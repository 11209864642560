import {
  isEmptyString,
  isInvalidEmail,
  isInvalidName,
  isInvalidPassword,
} from 'helpers/validations';
import { INPUTS_LIMITS } from './common';

export default {
  username: {
    label: 'Enter a display name',
    placeholder: 'Enter a display name',
    error: 'Should contain min two chars',
    limit: INPUTS_LIMITS.NAME,
    validationFn: isInvalidName,
  },
  firstname: {
    label: 'Enter first name',
    placeholder: 'Enter first name',
    error: 'Should contain min two chars',
    limit: INPUTS_LIMITS.NAME,
    validationFn: isInvalidName,
  },
  lastname: {
    label: 'Enter last name',
    placeholder: 'Enter last name',
    error: 'Should contain min two chars',
    limit: INPUTS_LIMITS.NAME,
    validationFn: isInvalidName,
  },
  email: {
    label: 'Enter your email',
    placeholder: 'Enter your Email',
    error: 'Invalid Email',
    validationFn: isInvalidEmail,
  },
  password: {
    label: 'Enter your password',
    placeholder: 'Enter your password',
    error:
      'Must contain lowercase and uppercase letters, a number and include one of !@#$%&* special characters',
    validationFn: isInvalidPassword,
  },
  newPassword: {
    label: 'Enter your new password',
    placeholder: 'Enter your new password',
    error:
      'Must contain lowercase and uppercase letters, a number and include one of !@#$%&* special characters',
    validationFn: isInvalidPassword,
  },
  repeatNewPassword: {
    label: 'Type your new password again',
    placeholder: 'Type your new password again',
    error:
      'Must contain lowercase and uppercase letters, a number and include one of !@#$%&* special characters',
    validationFn: isInvalidPassword,
  },
  licenseKey: {
    label: 'Enter license key',
    placeholder: 'Enter license key',
    error: 'Field can not be empty',
    validationFn: isEmptyString,
  },
  projectName: {
    label: 'Project name',
    placeholder: 'Enter project name',
    error: 'Should contain min two chars',
    limit: INPUTS_LIMITS.NAME,
    validationFn: isInvalidName,
  },
  confirmProjectName: {
    label: '',
    placeholder: 'Confirm project name',
    error: 'Should contain min two chars',
    validationFn: isInvalidName,
  },
  inviteEmail: {
    label: '',
    placeholder: 'Enter email address',
    error: 'Invalid Email',
    validationFn: isInvalidEmail,
  },
};
