import { SnackbarNotificationData } from 'types/common';
import { GeneralActions, GeneralActionsEnum } from '../types/generalTypes';

const setSnackbarNotification = (payload: null | SnackbarNotificationData): GeneralActions => ({
  type: GeneralActionsEnum.SET_SNACKBAR_NOTIFICATION,
  payload,
});

export default {
  setSnackbarNotification,
};
