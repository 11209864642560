import {
  AuthAssignPushTokenParams,
  AuthAssignPushTokenResponse,
  AuthDisablePushTokenParams,
  AuthDisablePushTokenResponse,
  AuthSendSignInCodeParams,
  AuthSendSignInCodeResponse,
  AuthSignInData,
  AuthSignInParams,
  AuthSignInResponse,
  AuthSignInWithCodeData,
  AuthSignInWithCodeParams,
  AuthSignInWithCodeResponse,
  AuthSignInWithGoogleData,
  AuthSignInWithGoogleParams,
  AuthSignInWithGoogleResponse,
} from 'types/Auth';
import ENDPOINTS from './endpoints';
import { apiClient } from './apiClient';

const endpoint = ENDPOINTS.AUTH;

const urls = {
  signIn: endpoint,
  signInWithGoogle: `${endpoint}/sign-in-with-google`,
  sendSignInCode: `${endpoint}/send-sign-in-code`,
  sinInWithCode: `${endpoint}/login-with-sign-in-code`,
  assignPushToken: `${endpoint}/assign-push-token`,
  disablePushToken: `${endpoint}/disable-push-token`,
};

const signIn = (data: AuthSignInParams): AuthSignInResponse =>
  apiClient.post<AuthSignInData>(urls.signIn, data).then((res) => res.data);

const signInWithGoogle = (data: AuthSignInWithGoogleParams): AuthSignInWithGoogleResponse =>
  apiClient.post<AuthSignInWithGoogleData>(urls.signInWithGoogle, data).then((res) => res.data);

const sendSignInCode = (data: AuthSendSignInCodeParams): AuthSendSignInCodeResponse =>
  apiClient.post(urls.sendSignInCode, data).then((res) => res.data);

const signInWithCode = (data: AuthSignInWithCodeParams): AuthSignInWithCodeResponse =>
  apiClient.post<AuthSignInWithCodeData>(urls.sinInWithCode, data).then((res) => res.data);

const assignPushToken = (data: AuthAssignPushTokenParams): AuthAssignPushTokenResponse =>
  apiClient.post(urls.assignPushToken, data).then((res) => res.data);

const disablePushToken = (data: AuthDisablePushTokenParams): AuthDisablePushTokenResponse =>
  apiClient.post(urls.assignPushToken, data).then((res) => res.data);

export default {
  signIn,
  signInWithGoogle,
  signInWithCode,
  sendSignInCode,
  assignPushToken,
  disablePushToken,
};
