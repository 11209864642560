import React from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, Button, Box, Container } from '@mui/material';
import { Logo } from 'components';
import { appLinks } from './routes';

const NotFound: React.FC = () => (
  <Container maxWidth="lg">
    <Box
      py={5}
      sx={{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 80px - 64px)',
      }}
    >
      <Box pb={8}>
        <Logo newLogo />
      </Box>
      <Typography variant="h3" gutterBottom>
        404
      </Typography>
      <Typography variant="h4" gutterBottom>
        Page not found
      </Typography>
      <Box pt={5}>
        <Button color="primary" component={NavLink} to={appLinks.index.link}>
          Go to main
        </Button>
      </Box>
    </Box>
  </Container>
);

export default NotFound;
