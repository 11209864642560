import { useCallback, useState } from 'react';
import { mutate } from 'swr';
import { useHistory } from 'react-router-dom';
import {
  alpha,
  Box,
  Button,
  buttonClasses,
  Grid,
  IconButton,
  Popover,
  Typography,
} from '@mui/material';
import { Loader, TextInput } from 'components';
import forms from 'constants/forms';
import colors from 'theme/colors';
import { useApiRequest } from 'hooks';
import projectsApi from 'api/projectsApi';
import { ProjectsItemData } from 'types/Projects';
import { TimesIcon } from 'components/icons';
import { appLinks } from 'routes/routes';

interface Props {
  project: ProjectsItemData;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  updateDataKey: string;
}

const DeleteProjectDialog = (props: Props): JSX.Element => {
  const { project, anchorEl, onClose, updateDataKey } = props;
  const history = useHistory();
  const { requestFn, isLoading } = useApiRequest(projectsApi.deleteItem);

  const [confirmProjectName, setConfirmProjectName] = useState('');
  const [isError, setIsError] = useState(false);

  const handleSubmit = useCallback(async () => {
    if (isError) {
      setIsError(false);
    }

    if (project.title === confirmProjectName) {
      await requestFn({
        args: project._id,
        successMessage: `${project.title} has been deleted`,
      });

      await mutate(updateDataKey);

      history.push(appLinks.projects.link);
      setConfirmProjectName('');
      onClose();
    } else {
      setIsError(true);
    }
  }, [
    isError,
    project.title,
    project._id,
    confirmProjectName,
    requestFn,
    updateDataKey,
    onClose,
    history,
  ]);

  const open = Boolean(anchorEl);
  const id = open ? `delete-project-${project._id}-popover` : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={isLoading ? undefined : onClose}
      BackdropProps={{
        invisible: false,
      }}
      sx={{
        marginTop: 1,
      }}
    >
      <Box sx={{ p: 2, maxWidth: 400 }}>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center">
              <Grid item>
                <Typography variant="subtitle1">Confirm delete</Typography>
              </Grid>
              <Grid item sx={{ ml: 2 }}>
                <IconButton onClick={onClose} disabled={isLoading}>
                  <TimesIcon
                    sx={(theme) => ({
                      color: theme.palette.mode === 'dark' ? colors.white : colors.black,
                    })}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography>
              There’s no way to restore a deleted project. Type the URL of the project below to
              proceed with deletion.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item sx={{ flexGrow: 1, mr: 2 }}>
                <TextInput
                  disabled={isLoading}
                  inputParams={{ ...forms.confirmProjectName, placeholder: project.title }}
                  value={confirmProjectName}
                  onChange={setConfirmProjectName}
                  isError={isError && project.title !== confirmProjectName}
                />
              </Grid>
              <Grid item>
                <Button
                  fullWidth={false}
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={isLoading || project.title !== confirmProjectName}
                  endIcon={isLoading ? <Loader inButton /> : undefined}
                  sx={{
                    backgroundColor: alpha(colors.red, 0.7),
                    '&:hover': {
                      backgroundColor: colors.red,
                    },
                    [`&.${buttonClasses.disabled}`]: {
                      backgroundColor: alpha(colors.red, 0.5),
                    },
                  }}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  );
};

export default DeleteProjectDialog;
