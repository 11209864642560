import axios, { AxiosInstance } from 'axios';

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
});

const setApiAuthorizationHeader = (token: string): void => {
  apiClient.defaults.headers.common['Auth-Token'] = token;
};

const deleteApiAuthorizationHeader = (): void => {
  delete apiClient.defaults.headers.common['Auth-Token'];
};

export { apiClient, setApiAuthorizationHeader, deleteApiAuthorizationHeader };
