import { useCallback, useMemo, useState } from 'react';
import { mutate } from 'swr';
import {
  Box,
  Button,
  buttonClasses,
  Collapse,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import colors from 'theme/colors';
import { ProjectsItemCreateParams, ProjectsItemData } from 'types/Projects';
import { useApiRequest, useSnackbarNotification } from 'hooks';
import projectsApi from 'api/projectsApi';
import { CopyIcon } from 'components/icons';
import { projectPublicUrl } from 'routes/routes';

interface Props {
  project: ProjectsItemData;
}

const ShareToWeb = (props: Props): JSX.Element | null => {
  const { project } = props;
  const { setSnackbarNotification } = useSnackbarNotification();
  const { requestFn, isLoading } = useApiRequest((data) =>
    projectsApi.updateItem(project._id, data)
  );

  const projectLink = useMemo(() => projectPublicUrl(project._id), [project._id]);

  const [isPubliclyAccessible, setIsPubliclyAccessible] = useState(!!project.publiclyAccessible);
  const [isPubliclyCommentable, setIsPubliclyCommentable] = useState(!!project.publiclyCommentable);

  const handleUpdate = useCallback(
    async (key: 'publiclyAccessible' | 'publiclyCommentable', value: boolean) => {
      const model: Partial<ProjectsItemCreateParams> = {
        [key]: value,
      };

      await requestFn({
        args: model,
      });

      await mutate(projectsApi.urls.getItem(project._id));
    },
    [requestFn, project._id]
  );

  const handleIsPubliclyAccessibleChange = useCallback(
    (_, checked: boolean) => {
      handleUpdate('publiclyAccessible', checked).then(() => setIsPubliclyAccessible(checked));
    },
    [handleUpdate]
  );

  const handleIsPubliclyCommentableChange = useCallback(
    (_, checked: boolean) => {
      handleUpdate('publiclyCommentable', checked).then(() => setIsPubliclyCommentable(checked));
    },
    [handleUpdate]
  );

  const handleLinkClick = useCallback(async () => {
    await navigator.clipboard.writeText(projectLink);

    setSnackbarNotification({ message: 'Link is copied to clipboard', severity: 'info' });
  }, [projectLink, setSnackbarNotification]);

  return (
    <Box
      sx={{
        borderRadius: 4,
        p: 2,
        background: (theme) =>
          theme.palette.mode === 'dark' ? colors.darkGrey : colors.lightGrey3,
      }}
    >
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <FormControlLabel
            sx={{ alignItems: 'flex-start' }}
            disabled={isLoading}
            control={
              <Switch
                id="shareToWeb"
                checked={isPubliclyAccessible}
                onChange={handleIsPubliclyAccessibleChange}
                disabled={isLoading}
              />
            }
            label={
              <Box sx={{ pt: 1 }}>
                <Typography color="text.primary">Share to web</Typography>
                <Typography color="text.secondary">Anyone with the link can view</Typography>
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            sx={{ alignItems: 'flex-start' }}
            disabled={isLoading}
            control={
              <Switch
                id="anyoneCanComment"
                onChange={handleIsPubliclyCommentableChange}
                checked={isPubliclyCommentable}
                disabled={isLoading}
              />
            }
            label={
              <Box sx={{ pt: 1 }}>
                <Typography color="text.primary">Anyone can comment</Typography>
                <Typography color="text.secondary">
                  Anyone with the link will be able to comment
                </Typography>
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Collapse in={isPubliclyAccessible}>
            <TextField label="Web link" value={projectLink} />
            <Button
              color="primary"
              onClick={handleLinkClick}
              sx={{ mt: 2, [`& .${buttonClasses.startIcon}>*:nth-of-type(1)`]: { fontSize: 24 } }}
              startIcon={<CopyIcon sx={{ fill: 'none' }} />}
            >
              Copy link
            </Button>
          </Collapse>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShareToWeb;
