import { CommentsDeviceTypeEnum, CommentsItemStatusEnum } from 'types/Comments';
import { CommentsActions, CommentsActionsEnum, StoreEditReplyIds } from '../types/commentsTypes';

const setActiveCommentId = (payload: string | undefined): CommentsActions => ({
  type: CommentsActionsEnum.SET_ACTIVE_COMMENT_ID,
  payload,
});

const setEditCommentId = (payload: string | undefined): CommentsActions => ({
  type: CommentsActionsEnum.SET_EDIT_COMMENT_ID,
  payload,
});

const setEditReplyIds = (payload: StoreEditReplyIds | undefined): CommentsActions => ({
  type: CommentsActionsEnum.SET_EDIT_REPLY_IDS,
  payload,
});

const addNotAvailableCommentId = (payload: string): CommentsActions => ({
  type: CommentsActionsEnum.ADD_NOT_AVAILABLE_COMMENT,
  payload,
});

const removeNotAvailableCommentId = (payload: string): CommentsActions => ({
  type: CommentsActionsEnum.REMOVE_NOT_AVAILABLE_COMMENT,
  payload,
});

const setRefreshCommentsData = (payload: boolean): CommentsActions => ({
  type: CommentsActionsEnum.SET_REFRESH_COMMENTS_DATA,
  payload,
});

const setDeviceTypeFilter = (payload: CommentsDeviceTypeEnum | undefined): CommentsActions => ({
  type: CommentsActionsEnum.SET_DEVICE_TYPE_FILTER,
  payload,
});

const setStatusTypeFilter = (payload: CommentsItemStatusEnum | undefined): CommentsActions => ({
  type: CommentsActionsEnum.SET_STATUS_TYPE_FILTER,
  payload,
});
const setPathnameFilter = (payload: string | undefined): CommentsActions => ({
  type: CommentsActionsEnum.SET_PATHNAME_FILTER,
  payload,
});

export default {
  setActiveCommentId,
  addNotAvailableCommentId,
  removeNotAvailableCommentId,
  setRefreshCommentsData,
  setPathnameFilter,
  setDeviceTypeFilter,
  setStatusTypeFilter,
  setEditCommentId,
  setEditReplyIds,
};
