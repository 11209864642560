import { SvgIcon, SvgIconProps } from '@mui/material';

const TimesIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6 18L18 6" stroke="currentColor" strokeWidth="1.5" />
    <path d="M6 6L18 18" stroke="currentColor" strokeWidth="1.5" />
  </SvgIcon>
);

export default TimesIcon;
