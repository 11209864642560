import { SyntheticEvent } from 'react';
import { Alert, alertClasses, Snackbar } from '@mui/material';
import colors from 'theme/colors';
import { useSnackbarNotification } from 'hooks';

const SnackbarNotification = (): JSX.Element | null => {
  const { snackbarNotification, setSnackbarNotification } = useSnackbarNotification();

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarNotification(null);
  };

  if (!snackbarNotification) {
    return null;
  }

  return (
    <Snackbar
      open
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      <Alert
        onClose={handleClose}
        severity={snackbarNotification.severity}
        variant="filled"
        sx={{
          width: '100%',
          fontSize: '14px',
          backgroundColor: colors.darkGrey,
          [`& .${alertClasses.action} > button`]: {
            color: colors.white,
          },
        }}
      >
        {snackbarNotification.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarNotification;
