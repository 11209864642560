import { SvgIcon, SvgIconProps } from '@mui/material';

const LogoIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="512"
    height="512"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M235.874 55.9999H276.126V106.314H275.577C343.283 115.083 396.917 168.716 405.685 236.422V235.874H456V276.126H405.685V275.578C396.917 343.284 343.284 396.917 275.578 405.685H276.126V456H235.874V405.685H236.422C168.716 396.917 115.083 343.283 106.314 275.577V276.126H55.9999V235.874H106.314V236.423C115.083 168.716 168.716 115.083 236.423 106.314H235.874V55.9999ZM256 145.308C194.867 145.308 145.308 194.867 145.308 256C145.308 317.134 194.867 366.692 256 366.692C317.134 366.692 366.692 317.134 366.692 256C366.692 194.867 317.134 145.308 256 145.308Z"
      fill="url(#paint0_linear)"
    />
    <mask
      id="greenlight-logo-mask"
      maskUnits="userSpaceOnUse"
      x="108"
      y="108"
      width="296"
      height="296"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M256 108.83C174.721 108.83 108.83 174.721 108.83 256C108.83 337.28 174.721 403.17 256 403.17C337.28 403.17 403.17 337.28 403.17 256C403.17 174.721 337.28 108.83 256 108.83ZM256 140.277C192.088 140.277 140.276 192.088 140.276 256C140.276 319.912 192.088 371.723 256 371.723C319.912 371.723 371.723 319.912 371.723 256C371.723 192.088 319.912 140.277 256 140.277Z"
        fill="url(#paint1_linear)"
      />
    </mask>
    <g mask="url(#greenlight-logo-mask)">
      <g filter="url(#greenlight-filter1)">
        <circle
          cx="256"
          cy="256"
          r="115.723"
          transform="rotate(-180 256 256)"
          stroke="#084477"
          strokeWidth="6.28931"
        />
      </g>
      <g filter="url(#greenlight-filter2)">
        <circle
          cx="256"
          cy="256"
          r="150.943"
          transform="rotate(-180 256 256)"
          stroke="#E7F1FA"
          strokeWidth="6.28931"
        />
      </g>
    </g>
    <defs>
      <filter
        id="greenlight-filter1"
        x="105.686"
        y="105.686"
        width="300.629"
        height="300.629"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="15.7233" result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="greenlight-filter2"
        x="51.5975"
        y="51.5974"
        width="408.805"
        height="408.805"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="25.1573" result="effect1_foregroundBlur" />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="100.025"
        y1="443.002"
        x2="278.564"
        y2="61.6351"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2746E7" />
        <stop offset="0.614583" stopColor="#A354F1" />
        <stop offset="1" stopColor="#1AB1ED" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="372.981"
        y1="115.749"
        x2="239.077"
        y2="401.774"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2746E7" />
        <stop offset="0.614583" stopColor="#A354F1" />
        <stop offset="1" stopColor="#1AB1ED" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export default LogoIcon;
