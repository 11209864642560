import { UsersItemData } from './Users';

export enum CommentsOperatingSystemEnum {
  MAC_OS = 'MacOS',
  WINDOWS = 'Windows',
  LINUX = 'Linux OS',
}

export enum CommentsDeviceTypeEnum {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

export enum CommentsBrowserEnum {
  CHROME = 'Chrome',
  FIREFOX = 'Firefox',
  SAFARI = 'Safari',
}

export enum CommentsItemStatusEnum {
  NEW = 'new',
  RESOLVED = 'resolved',
  DELETED = 'deleted',
}

export interface CommentsPublicCommenterData {
  firstName?: string;
  lastName?: string;
  email: string;
}

export interface CommentsItemBody {
  body: string;
  draftBody: string;
}

export interface CommentsItemGeneralCreateParams extends CommentsItemBody {
  projectId: string;
}

export interface CommentsItemCreateParams extends CommentsItemBody {
  projectId: string;
  positionX: string;
  positionY: string;
  operatingSystem: CommentsOperatingSystemEnum | '';
  browser: CommentsBrowserEnum | string; // deprecated
  resolution: string;
  scrollPosition: string;
  pathname: string;
  cssSelector: string;
  deviceType: CommentsDeviceTypeEnum;
  screenshot: File;
}

export type CommentsItemUpdateParams = CommentsItemBody;

export type CommentsReplyItemCreateParams =
  | CommentsItemBody
  | (CommentsItemBody & CommentsPublicCommenterData);

export interface CommentsReplyItemData extends CommentsItemBody {
  _id: string;
  author?: Pick<UsersItemData, 'username' | 'email' | '_id' | 'avatar'>;
  publicCommenter?: CommentsPublicCommenterData;
  createdAt: string;
  updatedAt: string;
  pathname?: string;
}

export interface CommentsItemData extends CommentsItemBody {
  _id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  project: string;
  pathname: string;
  author: Pick<UsersItemData, '_id' | 'email' | 'username' | 'avatar'>;
  status: CommentsItemStatusEnum;
  orderNumber: number;
  replies: CommentsReplyItemData[];
  versions: string[];

  screenshotUrl?: string;
  positionX?: string;
  positionY?: string;
  operatingSystem?: CommentsOperatingSystemEnum;
  browser?: CommentsBrowserEnum; // deprecated
  resolution?: string;
  scrollPosition?: string;
  cssSelector?: string;
  deviceType?: CommentsDeviceTypeEnum;
}

export interface CommentsListParams {
  projectId: string;
  status?: CommentsItemStatusEnum;
  pathname?: string;
}

export type CommentsListData = CommentsItemData[];

export type CommentsListResponse = Promise<CommentsListData>;

export type CommentsItemResponse = Promise<CommentsItemData>;

export type CommentsReplyItemResponse = Promise<CommentsReplyItemData>;

export interface CommentsPathnameListParams {
  projectId: string;
}

export interface CommentsPathnameItemData {
  commentsCount: number;
  pathname: string;
}

export type CommentsPathnameListData = CommentsPathnameItemData[];

export type CommentsPathnameListResponse = Promise<CommentsPathnameListData>;
