import React from 'react';
import { EmojiPluginConfig } from '@draft-js-plugins/emoji';
import { MentionPluginConfig } from '@draft-js-plugins/mention';
import { EmojiIcon } from 'components/icons';
import { LinkifyPluginConfig } from '@draft-js-plugins/linkify';
import { EmojiPluginTheme, MentionPluginTheme } from 'types/DraftJs';

const emojiPluginTheme: EmojiPluginTheme = {
  emoji: 'emoji',
  emojiSuggestions: 'emojiSuggestions',
  emojiSuggestionsEntry: 'emojiSuggestionsEntry',
  emojiSuggestionsEntryFocused: 'emojiSuggestionsEntryFocused',
  emojiSuggestionsEntryText: 'emojiSuggestionsEntryText',
  emojiSuggestionsEntryIcon: 'emojiSuggestionsEntryIcon',
  emojiSelect: 'emojiSelect',
  emojiSelectButton: 'emojiSelectButton',
  emojiSelectButtonPressed: 'emojiSelectButtonPressed',
  emojiSelectPopover: 'emojiSelectPopover',
  emojiSelectPopoverClosed: 'emojiSelectPopoverClosed',
  emojiSelectPopoverTitle: 'emojiSelectPopoverTitle',
  emojiSelectPopoverGroups: 'emojiSelectPopoverGroups',
  emojiSelectPopoverGroup: 'emojiSelectPopoverGroup',
  emojiSelectPopoverGroupTitle: 'emojiSelectPopoverGroupTitle',
  emojiSelectPopoverGroupList: 'emojiSelectPopoverGroupList',
  emojiSelectPopoverGroupItem: 'emojiSelectPopoverGroupItem',
  emojiSelectPopoverToneSelect: 'emojiSelectPopoverToneSelect',
  emojiSelectPopoverToneSelectList: 'emojiSelectPopoverToneSelectList',
  emojiSelectPopoverToneSelectItem: 'emojiSelectPopoverToneSelectItem',
  emojiSelectPopoverEntry: 'emojiSelectPopoverEntry',
  emojiSelectPopoverEntryFocused: 'emojiSelectPopoverEntryFocused',
  emojiSelectPopoverEntryIcon: 'emojiSelectPopoverEntryIcon',
  emojiSelectPopoverNav: 'emojiSelectPopoverNav',
  emojiSelectPopoverNavItem: 'emojiSelectPopoverNavItem',
  emojiSelectPopoverNavEntry: 'emojiSelectPopoverNavEntry',
  emojiSelectPopoverNavEntryActive: 'emojiSelectPopoverNavEntryActive',
  emojiSelectPopoverScrollbar: 'emojiSelectPopoverScrollbar',
  emojiSelectPopoverScrollbarThumb: 'emojiSelectPopoverScrollbarThumb',
  emojiSelectPopoverScrollbarOuter: 'emojiSelectPopoverScrollbarOuter',
};

const mentionPluginTheme: MentionPluginTheme = {
  mention: 'mention',
  mentionSuggestions: 'mentionSuggestions',
  mentionSuggestionsEntry: 'mentionSuggestionsEntry',
  mentionSuggestionsEntryFocused: 'mentionSuggestionsEntryFocused',
  mentionSuggestionsEntryText: 'mentionSuggestionsEntryText',
  mentionSuggestionsPopup: 'mentionSuggestionsPopup',
  mentionSuggestionsPopupVisible: 'mentionSuggestionsPopupVisible',
  mentionSuggestionsEntryAvatar: 'mentionSuggestionsEntryAvatar',
};

const emojiPluginConfig: EmojiPluginConfig = {
  theme: emojiPluginTheme,
  selectButtonContent: React.createElement(EmojiIcon),
  useNativeArt: true,
};

const mentionPluginConfig: MentionPluginConfig = {
  entityMutability: 'IMMUTABLE',
  theme: mentionPluginTheme,
  mentionPrefix: '@',
  mentionTrigger: '@',
  supportWhitespace: true,
};

const linkifyPluginConfig: LinkifyPluginConfig = {
  theme: {
    link: 'link',
  },
};

export {
  emojiPluginConfig,
  mentionPluginConfig,
  linkifyPluginConfig,
  mentionPluginTheme,
  emojiPluginTheme,
};
