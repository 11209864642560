import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Loader } from 'components';
import { deleteApiAuthorizationHeader, setApiAuthorizationHeader } from 'api/apiClient';
import { getToken } from 'helpers/cookies';
import usersApi from 'api/usersApi';
import { useUserData } from 'hooks';

interface Props {
  children: ReactNode;
}

const UserDataWrapper = (props: Props): JSX.Element => {
  const { children } = props;
  const { setUserData } = useUserData();

  const token = useMemo(() => getToken(), []);
  const [isLoading, setIsLoading] = useState(!!token);

  const getCurrentUser = useCallback(async () => {
    if (token) {
      try {
        setApiAuthorizationHeader(token);

        const resp = await usersApi.getCurrent();

        setUserData(resp);
        // eslint-disable-next-line
      } catch (e: any) {
        if (e?.response?.status === 401) {
          deleteApiAuthorizationHeader();

          setUserData(null);
        }
      }
    }
  }, [setUserData, token]);

  useEffect(() => {
    getCurrentUser().then(() => setIsLoading(false));
  }, [getCurrentUser]);

  if (isLoading) {
    return <Loader height="100vh" inline={false} />;
  }

  return <span>{children}</span>;
};

export default UserDataWrapper;
