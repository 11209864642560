import { ReactNode, useMemo } from 'react';
import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import createMuiTheme from 'theme/muiThemeConfig';
import 'theme/index.css';
import { SnackbarNotification } from 'components';

interface Props {
  children: ReactNode;
}

const ThemeWrapper = (props: Props): JSX.Element => {
  const { children } = props;
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const themeConfig = useMemo(
    () => createMuiTheme(prefersDarkMode ? 'dark' : 'light'),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={themeConfig}>
      <CssBaseline />
      <SnackbarNotification />
      {children}
    </ThemeProvider>
  );
};

export default ThemeWrapper;
