import { ChangeEvent } from 'react';
import {
  Box,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from '@mui/material';
import { NotificationsAlertCheckboxOption } from 'types/common';
import { NotificationsProjectSettingsData } from 'types/Notifications';
import { Loader } from 'components';

interface Props {
  title: string;
  isLoading: boolean | undefined;
  onlyMentionsKey: string;
  collapseOnlyOptionIn: boolean;
  data: NotificationsProjectSettingsData;
  options: NotificationsAlertCheckboxOption[];
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxGroup = (props: Props): JSX.Element | null => {
  const { onlyMentionsKey, options, data, title, onChange, isLoading, collapseOnlyOptionIn } =
    props;

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend" sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle1" color="text.primary">
          {title}
        </Typography>
        {isLoading && (
          <Box ml={2}>
            <Loader inButton color="primary" />
          </Box>
        )}
      </FormLabel>
      <FormGroup>
        {options.map((o) =>
          o.value === onlyMentionsKey ? (
            <Collapse in={collapseOnlyOptionIn}>
              <FormControlLabel
                control={<Checkbox checked={data[o.value]} onChange={onChange} name={o.value} />}
                label={o.label}
              />
            </Collapse>
          ) : (
            <FormControlLabel
              control={<Checkbox checked={data[o.value]} onChange={onChange} name={o.value} />}
              label={o.label}
            />
          )
        )}
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxGroup;
