import { CommentsDeviceTypeEnum, CommentsItemStatusEnum } from 'types/Comments';

export interface StoreEditReplyIds {
  commentId: string;
  replyId: string;
}

export interface StoreCommentsState {
  activeCommentId: string | undefined;
  editCommentId: string | undefined;
  editReplyIds: StoreEditReplyIds | undefined;
  notAvailableCommentIds: string[];
  refreshCommentsData: boolean;

  deviceTypeFilter: CommentsDeviceTypeEnum | undefined;
  statusTypeFilter: CommentsItemStatusEnum | undefined;
  pathnameFilter: string | undefined;
}

export enum CommentsActionsEnum {
  SET_ACTIVE_COMMENT_ID = 'SET_ACTIVE_COMMENT_ID',
  SET_EDIT_COMMENT_ID = 'SET_EDIT_COMMENT_ID',
  SET_EDIT_REPLY_IDS = 'SET_EDIT_REPLY_IDS',
  ADD_NOT_AVAILABLE_COMMENT = 'ADD_NOT_AVAILABLE_COMMENT',
  REMOVE_NOT_AVAILABLE_COMMENT = 'REMOVE_NOT_AVAILABLE_COMMENT',
  SET_REFRESH_COMMENTS_DATA = 'SET_REFRESH_COMMENTS_DATA',
  SET_DEVICE_TYPE_FILTER = 'SET_DEVICE_TYPE_FILTER',
  SET_STATUS_TYPE_FILTER = 'SET_STATUS_TYPE_FILTER',
  SET_PATHNAME_FILTER = 'SET_PATHNAME_FILTER',
}

interface SetActiveCommentId {
  type: CommentsActionsEnum.SET_ACTIVE_COMMENT_ID;
  payload: string | undefined;
}

interface SetEditCommentId {
  type: CommentsActionsEnum.SET_EDIT_COMMENT_ID;
  payload: string | undefined;
}

interface SetEditReplyIds {
  type: CommentsActionsEnum.SET_EDIT_REPLY_IDS;
  payload: StoreEditReplyIds | undefined;
}

interface AddNotAvailableCommentId {
  type: CommentsActionsEnum.ADD_NOT_AVAILABLE_COMMENT;
  payload: string;
}

interface RemoveNotAvailableCommentId {
  type: CommentsActionsEnum.REMOVE_NOT_AVAILABLE_COMMENT;
  payload: string;
}

interface SetRefreshCommentsData {
  type: CommentsActionsEnum.SET_REFRESH_COMMENTS_DATA;
  payload: boolean;
}

interface SetDeviceTypeFilter {
  type: CommentsActionsEnum.SET_DEVICE_TYPE_FILTER;
  payload: CommentsDeviceTypeEnum | undefined;
}

interface SetStatusTypeFilter {
  type: CommentsActionsEnum.SET_STATUS_TYPE_FILTER;
  payload: CommentsItemStatusEnum | undefined;
}

interface SetPathnameFilter {
  type: CommentsActionsEnum.SET_PATHNAME_FILTER;
  payload: string | undefined;
}

export type CommentsActions =
  | SetActiveCommentId
  | SetEditCommentId
  | AddNotAvailableCommentId
  | RemoveNotAvailableCommentId
  | SetRefreshCommentsData
  | SetDeviceTypeFilter
  | SetStatusTypeFilter
  | SetPathnameFilter
  | SetEditReplyIds;
