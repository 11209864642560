import { Suspense, useMemo } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'store';
import Router from 'routes/Router';
import ThemeWrapper from './ThemeWrapper';
import SwrConfigWrapper from './SWRConfigWrapper';
import UserDataWrapper from './UserDataWrapper';

const App = (): JSX.Element => {
  const suspenseFallback = useMemo(
    () => (
      <Backdrop open>
        <CircularProgress color="primary" />
      </Backdrop>
    ),
    []
  );

  return (
    <BrowserRouter>
      <SwrConfigWrapper>
        <Provider store={store}>
          <ThemeWrapper>
            <Suspense fallback={suspenseFallback}>
              <UserDataWrapper>
                <Router />
              </UserDataWrapper>
            </Suspense>
          </ThemeWrapper>
        </Provider>
      </SwrConfigWrapper>
    </BrowserRouter>
  );
};

export default App;
