import { UsersItemData } from 'types/Users';

export interface ResendCodeTimerData {
  timer: number;
  reloadAt: number;
}

export interface StoreAuthGuestUserData {
  firstName: string;
  lastName: string;
  email: string;
}

export interface StoreAuthState {
  userData: UsersItemData | null;
  resendCodeEmail: string | undefined;
  resendCodeTimerData: ResendCodeTimerData | undefined;
  guestUserData: StoreAuthGuestUserData | undefined;
  isGuestUserModalOpen: boolean;
}

export enum AuthActionsEnum {
  SET_USER_DATA = 'SET_USER_DATA',
  SET_RESEND_CODE_EMAIL = 'SET_RESEND_CODE_EMAIL',
  SET_RESEND_CODE_TIMER_DATA = 'SET_RESEND_CODE_TIMER_DATA',
  SET_GUEST_USER_DATA = 'SET_GUEST_USER_DATA',
  SET_IS_GUEST_USER_MODAL_OPEN = 'SET_IS_GUEST_USER_MODAL_OPEN',
}

interface SetUserData {
  type: AuthActionsEnum.SET_USER_DATA;
  payload: UsersItemData | null;
}

interface SetResendCodeEmail {
  type: AuthActionsEnum.SET_RESEND_CODE_EMAIL;
  payload: string | undefined;
}

interface SetResendCodeTimerData {
  type: AuthActionsEnum.SET_RESEND_CODE_TIMER_DATA;
  payload: ResendCodeTimerData | undefined;
}

interface SetGuestUserData {
  type: AuthActionsEnum.SET_GUEST_USER_DATA;
  payload: StoreAuthGuestUserData | undefined;
}

interface SetIsGuestUserModalOpen {
  type: AuthActionsEnum.SET_IS_GUEST_USER_MODAL_OPEN;
  payload: boolean;
}

export type AuthActions =
  | SetUserData
  | SetResendCodeTimerData
  | SetResendCodeEmail
  | SetGuestUserData
  | SetIsGuestUserModalOpen;
