import { SvgIcon, SvgIconProps } from '@mui/material';

const BellIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="10.49"
      y="5"
      width="2.44"
      height="2.44"
      rx="1.22"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M10.49 18.42C10.49 19.0938 11.0362 19.64 11.71 19.64V19.64C12.3838 19.64 12.93 19.0938 12.93 18.42V17.2H10.49V18.42Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M11.71 7.44C9.01485 7.44 7.5 9.5 7.44 11.32V13.2898C7.44 13.9639 7.10042 14.5927 6.53671 14.9623L6.41445 15.0425C6.08859 15.2562 5.66866 15.3212 5.35926 15.558C5.14085 15.7253 5 15.9887 5 16.285C5 16.7903 5.40966 17.2 5.915 17.2H17.505C18.0103 17.2 18.42 16.7903 18.42 16.285C18.42 15.9887 18.2791 15.7253 18.0607 15.558C17.7513 15.3212 17.3314 15.2562 17.0055 15.0425L16.8833 14.9623C16.3196 14.5927 15.98 13.9639 15.98 13.2898V11.32C15.98 9.5 14.4051 7.44 11.71 7.44Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </SvgIcon>
);

export default BellIcon;
